import { MemberReward } from '@vista-digital/ocapi-types/v1'
import { MemberRewardListProps } from '@vista/omnichannel-components-ui'
import { MemberRewardListPropsFromBe } from '../../../types'
import { defaultMemberRewardListProps } from '@vista/omnichannel-components-ui/lib/src/lib/components/member-reward-list/types/props'

export function rewardProps(props: MemberRewardListPropsFromBe): MemberRewardListProps {
    return {
        ...defaultMemberRewardListProps as any,
        ...props,
        assets: {
            noRewardsIconName: 'curzon-gift',
            failedToLoadDataIconName: 'curzon-warning',
            actionButtonListItemIconName: defaultMemberRewardListProps.assets.actionButtonListItemIconName,
        }
    }
}
export function showOnlyTwoRewards(props: MemberRewardListProps): MemberRewardListProps {
    return {
        ...props,
        config: {
            ...props.config,
            rewardFilterFn: (memberRewardList: MemberReward[]) =>
                memberRewardList.filter((_: MemberReward, idx) => idx < 2),
        },
        
    }
}
