import {
    ButtonColor,
    ButtonIconPosition,
    ButtonSize,
    FieldFormatType,
    InputFieldDisplayText,
    MemberTicketRedemptionCardPickerProps,
    TicketRedemptionCodeFormConfig,
    TicketRedemptionCodeFormProps
} from '@vista/omnichannel-components-ui'
import {defaultTicketRedemptionCodeFormCodeFieldDisplayText, defaultTicketRedemptionCodeFormPinFieldDisplayText, defaultTicketRedemptionCodeFormTypeFieldDisplayText} from '@vista/omnichannel-components-ui/lib/src/lib/components/showtime/ticket-redemption-code-form-field/types/display-text'

import { TicketRedemptionCodeFormValidationConfig } from '@vista/omnichannel-components-ui/lib/src/lib/components/showtime/ticket-redemption-code-form/types/validation-config'
import { TicketRedemptionComponentPropsFromBe } from '../../types'
import {defaultMemberTicketRedemptionCardPickerProps} from '@vista/omnichannel-components-ui/lib/src/lib/components/showtime/member-ticket-redemption-card-picker/types/props'
import {defaultTicketRedemptionCodeFormProps} from '@vista/omnichannel-components-ui/lib/src/lib/components/showtime/ticket-redemption-code-form/types/props'

// Todo - update TicketRedemptionComponentPropsFromBe with the new properties

export function formProps(props: TicketRedemptionComponentPropsFromBe): TicketRedemptionCodeFormProps {
    const returnFormProps =  {
        displayText: {
            ...defaultTicketRedemptionCodeFormProps.displayText, 
            errorVoucherCredentialsInvalidMessage: props?.form?.displayText?.errorInvalidVoucherBarcodeOrPinMessage || defaultTicketRedemptionCodeFormProps.displayText.errorVoucherCredentialsInvalidMessage,
            errorVoucherAlreadyAddedMessage: props?.form?.displayText?.errorVoucherAlreadyAddedToOrderMessage || defaultTicketRedemptionCodeFormProps.displayText.errorVoucherAlreadyAddedMessage,
            errorThirdPartyMemberCardDeclinedMessage: props?.form?.displayText?.errorThirdPartyMemberCardDeclinedMessage || defaultTicketRedemptionCodeFormProps.displayText.errorThirdPartyMemberCardDeclinedMessage,
            errorThirdPartyMemberCardAlreadyAddedMessage: props?.form?.displayText?.errorThirdPartyMemberCardAlreadyAddedToOrderMessage || defaultTicketRedemptionCodeFormProps.displayText.errorThirdPartyMemberCardAlreadyAddedMessage,
            errorNoValidTicketsAvailableForThirdPartyMemberCardMessage: props?.form?.displayText?.errorNoValidTicketsAvailableForThirdPartyMemberCardMessage || defaultTicketRedemptionCodeFormProps.displayText.errorNoValidTicketsAvailableForThirdPartyMemberCardMessage,
            errorFailedToAddMessage: props?.form?.displayText?.errorFailedToAddMessage || defaultTicketRedemptionCodeFormProps.displayText.errorFailedToAddMessage,
            noSearchResultsFoundMessage: props?.form?.displayText?.noSearchResultsFoundMessage || defaultTicketRedemptionCodeFormProps.displayText.noSearchResultsFoundMessage,
            submitButtonLabel: props?.form?.displayText?.addButtonLabel || defaultTicketRedemptionCodeFormProps.displayText.submitButtonLabel,
        },
        config: formPropsConfig(props),
        children: null,
        assets: {},
        events: {},
    }

    /*@ts-ignore*/
    delete returnFormProps.config.minimumCodeLength
    return returnFormProps
}

export interface FormFieldPropsDisplayTextProps {
    code:InputFieldDisplayText,
    pin:InputFieldDisplayText,
    type:InputFieldDisplayText
}

export const formFieldPropsDisplayText = (props: TicketRedemptionComponentPropsFromBe):FormFieldPropsDisplayTextProps => {
    return {
        code: {
            label: props?.form?.displayText?.fieldCodeLabel || defaultTicketRedemptionCodeFormCodeFieldDisplayText.label,
            requiredMessage: props?.form?.displayText?.errorCodeRequiredMessage || defaultTicketRedemptionCodeFormCodeFieldDisplayText.requiredMessage,
            placeholder: props?.form?.displayText?.fieldCodePlaceholder || defaultTicketRedemptionCodeFormCodeFieldDisplayText.placeholder,
        },
        pin: {
            label: props?.form?.displayText?.fieldPinLabel || defaultTicketRedemptionCodeFormPinFieldDisplayText.label,
            requiredMessage: props?.form?.displayText?.errorPinRequiredMessage || defaultTicketRedemptionCodeFormPinFieldDisplayText.requiredMessage,
            placeholder: props?.form?.displayText?.fieldPinPlaceholder || defaultTicketRedemptionCodeFormPinFieldDisplayText.placeholder,
        },
        type: {
            label: props?.form?.displayText?.fieldTypeLabel || defaultTicketRedemptionCodeFormTypeFieldDisplayText.label,
            requiredMessage: props?.form?.displayText?.errorCodeTypeRequiredMessage || defaultTicketRedemptionCodeFormTypeFieldDisplayText.requiredMessage,
            placeholder: props?.form?.displayText?.fieldTypePlaceholder || defaultTicketRedemptionCodeFormTypeFieldDisplayText.placeholder,
        }
    }
}


export function formPropsConfig(props: TicketRedemptionComponentPropsFromBe): TicketRedemptionCodeFormConfig {
    const validation: TicketRedemptionCodeFormValidationConfig = {
        code: {
            required: true,
            minLength: 4,
            type: FieldFormatType.Text,
        },
        pin: {
            type: FieldFormatType.Number,
        },
    }

    const cancelButton: TicketRedemptionCodeFormConfig['cancelButton'] = {
        show: true,
        size: ButtonSize.Medium,
        color: ButtonColor.Secondary,
        iconPosition: ButtonIconPosition.Right,
    }
    const submitButton: TicketRedemptionCodeFormConfig['submitButton'] = {
        show: true,
        size: ButtonSize.Medium,
        color: ButtonColor.Primary,
        iconPosition: ButtonIconPosition.Right,
    }

    const missingConfigProps = {
        validation,
        cancelButton,
        submitButton,
    }
    return {
        ...props.form.config,
        ...missingConfigProps,
    }
}
// Todo - update TicketRedemptionComponentPropsFromBe with the new properties
export function cardProps(props: TicketRedemptionComponentPropsFromBe): MemberTicketRedemptionCardPickerProps {
    return {
        displayText: {...defaultMemberTicketRedemptionCardPickerProps.displayText, ...props.card.displayText},
        config: {...defaultMemberTicketRedemptionCardPickerProps.config, ...props.card.config},
        assets: cardAssets(),
    }
}

function cardAssets(): MemberTicketRedemptionCardPickerProps['assets'] {
    return {
        addCardButtonIconName: 'plus-circle',
    }
}
