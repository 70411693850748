import { getFilmById, getMemberHash, loadMember } from '@vista/omnichannel-components-domain'

import { isDev } from './isDev'
import { isSignedIn } from '../utils/member'

export function getFilmHoCodeFromFilmId(filmId: string): string {
    const film = getFilmById(filmId)

    if (!film) {
        return null
    }
    return film.hoCode
}

export class MovioTracker {

    async setMember(): Promise<boolean> {
        // Determine if member is signed in and details available

        if (isSignedIn()) {
            await loadMember()
            const memberHash = getMemberHash()

            const memberDetails = {
                id: memberHash,
                hashed: true,
            }

            if (memberHash) {
                mt('set', 'memberId', memberDetails)
                return true
            }
        }

        return false
    }

    checkMT(): boolean {
        //Check if MovieTracker object actually exists
        if (!mt) {
            console.error('MT did not load')
            return false
        }

        return true
    }

    async validateMT(): Promise<boolean> {
        const setMemberResult = await this.setMember()

        if (!(this.checkMT() && setMemberResult)) {
            console.error('MT memberResult returned false')
            return false
        }

        return true
    }

    async initiateLogin(): Promise<void> {
        //Checks if a user has just logged in
        if (isSignedIn()) {
            this.memberLogin()
        }
    }

    async memberLogin(): Promise<void> {
        // A user logged in the website

        const valid = await this.validateMT()

        if (!valid) {
            return
        }

        mt('send', 'TrackMemberLogin', { loginDateTime: new Date() },
        function (success) {
            if (isDev()) {
                console.log('Sent TrackMemberLogin event: ', success)
            }
        })
    }

    async movieDetail(filmId: string, waitMS: number): Promise<void> {
        // A user visited a movie detail page for a certain amount of time
        const valid = await this.validateMT()
        const filmHoCodeString = getFilmHoCodeFromFilmId(filmId)

        if (!valid) {
            return
        }

        setTimeout(() => {
            mt('send', 'TrackMovieDetail', {
                movieId: filmHoCodeString,
                viewDuration: waitMS / 1000,
                viewDateTime: new Date(),
            },
            function (success) {
                if (isDev()) {
                    console.log('Sent TrackMovieDetail event: ', success)
                    console.log(
                        'Tracked film details page viewing for: ' +
                        filmHoCodeString
                    )
                }
            })
        }, waitMS)
    }

    async movieTrailer(filmId: string): Promise<void> {
        // A user watched a movie trailer

        const valid = await this.validateMT()
        const filmHoCodeString = getFilmHoCodeFromFilmId(filmId)

        if (!valid) {
            console.error('MovieTracker validation failed')
            return
        }

        //Detect if the modal appears
        let modal
        let i = 0

        const modalDetector = setInterval(async () => {
            if (document.querySelector('.v-film-trailer-button__modal')) {
                modal = document.querySelector('.v-film-trailer-button__modal')
                clearInterval(modalDetector)
                await continueTrackMovieTrailer()
            } else if (i >= 10) {
                clearInterval(modalDetector)
                console.error('Trailer Modal not detected')
            }
            i++
        }, 100)

        const continueTrackMovieTrailer = async () => {
            // Wait until 20 seconds of the modal being open until registering a trailer view
            const waitTimeInSeconds = 20

            if (modal) {
                // Timeout to give the modal time to render
                setTimeout(() => {
                    const videoSrc = modal
                        .querySelector('video')
                        .getAttribute('src')

                    if (!videoSrc) {
                        console.error('Movietrailer Modal Src not found')
                    }

                    let i = 0

                    // Start timer
                    const modalTimer = setInterval(() => {
                        // Check every second to see if the videoSrc is still the same
                        if (i >= waitTimeInSeconds) {
                            if (
                                videoSrc ===
                                modal?.querySelector('video')?.getAttribute('src')
                            ) {
                                // It's been 20 seconds and it's still the same video - trigger the track
                                clearInterval(modalTimer)
                                triggerTrack()
                            }
                        } else if (
                            videoSrc !==
                            modal?.querySelector('video')?.getAttribute('src')
                        ) {
                            clearInterval(modalTimer)
                        }
                        i++
                    }, 1000)
                }, 1000)
            }
        }

        // Trigger the actual track
        const triggerTrack = () => {
            mt(
                'send',
                'TrackMovieTrailer',
                { movieId: filmHoCodeString, viewDateTime: new Date() },
                function (success) {
                    if (isDev()) {
                        console.log('Sent TrackMovieTrailer event: ', success)
                        console.log(
                            'Tracked TrackMovieTrailer for filmHoCode: ' +
                            filmHoCodeString
                        )
                    }
                }
            )
        }
    }

    async movieWatchList(filmId: string, isDelete: boolean): Promise<void> {
        // A user added/removed a movie to his/her watch list

        const valid = await this.validateMT()
        const filmHoCodeString = getFilmHoCodeFromFilmId(filmId)

        if (!valid) {
            return
        }

        if (!isDelete) {
            mt('send', 'TrackMovieWatchlist', { movieId: filmHoCodeString },
            function (success) {
                if (isDev()) {
                    console.log('Sent TrackAddToWatchlist event: ', success)
                    console.log(
                        'Added film to watchlist: ' +
                        filmHoCodeString
                    )
                }
            }
        )
        } else {
            mt('delete', 'TrackMovieWatchlist', { movieId: filmHoCodeString },
            function (success) {
                if (isDev()) {
                    console.log('Sent TrackRemoveFromWatchlist event: ', success)
                    console.log(
                        'Removed film from watchlist: ' +
                        filmHoCodeString
                    )
                }
            }
        )
        }
    }

    async abandonOrder(filmId: string): Promise<void> {
        // A user abandoned an order in the booking flow

        const valid = await this.validateMT()
        const filmHoCodeString = getFilmHoCodeFromFilmId(filmId)

        if (!valid) {
            return
        }

        mt(
            'send',
            'TrackAbortedPurchase', {
            movieId: filmHoCodeString,
            abortDateTime: new Date()
        },
            function (success) {
                if (isDev()) {
                    console.log('Sent AbandonOrder event: ', success)
                    console.log(
                        'Abandoned ticket purchase for film id: ' +
                        filmHoCodeString
                    )
                }
            }
        )
    }
}