import { SiteItemPickerProps, TabsDisplayType } from '@vista/omnichannel-components-ui'

import { OrderItemPickerPropsFromBe } from '../../types'
import { quantityLimitAdapter } from '../../utils'

export function siteItemPickerProps(props: OrderItemPickerPropsFromBe): SiteItemPickerProps {
    const returnProps =  {
        ...props,
        displayText: displayText(props),
        config: config(props),
        events: events(),
        assets: assets(),
        cdnOptions: cdnOptions(),
    }

    delete returnProps.workflow
    /*@ts-ignore*/
    delete returnProps.displayText.bookingFeeLabel

    return returnProps
}

function events(): SiteItemPickerProps['events'] {
    return {}
}

function cdnOptions(): SiteItemPickerProps['cdnOptions'] {
    return {
        itemThumbnail: { width: 520 },
        itemImage: { width: 640 },
        itemOptionImage: { width: 120 },
    }
}
function assets(): SiteItemPickerProps['assets'] {
    return {
        failedToLoadDataIconName: 'curzon-warning',
        noItemsIconName: 'curzon-fnb',
        addItemButtonIconName: 'shopping-cart',
        closeModalButtonIconName: 'cross',
    }
}

function config(props: OrderItemPickerPropsFromBe): SiteItemPickerProps['config'] {
    return {
        ...props.config,
        quantityLimit: quantityLimitAdapter(props.config.quantityLimit),
        tabs: {
            displayType: TabsDisplayType.Primary
        }
    }
}

function displayText(props: OrderItemPickerPropsFromBe): SiteItemPickerProps['displayText'] {
    const defaultDisplayText = {
        itemButtonHoverLabel: 'Add to order',
        addToOrderButtonLabel: 'Add to cart ${total}',
        failedToLoadDataTitle: 'Sorry, something went wrong.',
        failedToLoadDataMessage: 'Refresh your browser to try loading items again.',
        noItemsTitle: 'Sorry, there are no items available for this cinema',
        noItemsMessage:
            "This location currently doesn't support online concession sales. Please make your purchases on arrival or select a different cinema.",
        failedToSaveItemSelectionsMessage: 'Sorry, something went wrong.',
        failedToValidateItemSelectionsMessage: 'Please verify your selection.',
        insufficientItemsSelectedMessage: 'You must select at least ${minimum}.',
        successfullyAddedItemToOrderMessage: 'Successfully added to cart!',
        quantityCounterLabel: 'Quantity',
        totalSavingsLabel: 'You save ${total}',
        insufficientModifiersSelectedMessage: 'You must select at least ${minimum}.',
        parentSalesItemButtonPriceLabel: 'From ${minPrice}',
        parentSalesItemGenericDiscountLabel: 'Discount',
        parentSalesItemDefaultPromptText: 'Choose an option',
        modifierGroupDefaultPromptText: 'Choose an option',
        modifierQuantityLimitMinZeroMaxGreaterThanOneLabel: 'Choose up to ${maximum}',
        modifierQuantityLimitMinOneMaxUnlimitedLabel: 'Required',
        modifierQuantityLimitMinOneMaxOneLabel: 'Required',
        modifierQuantityLimitMinOneMaxGreaterThanOneLabel: 'Required (Max: ${maximum})',
        modifierQuantityLimitMinGreaterThanOneMaxUnlimitedLabel: 'Choose at least ${minimum}',
        modifierQuantityLimitMinGreaterThanOneMaxGreaterThanOneLabel: 'Choose ${minimum} (Max: ${maximum})',
        modifierQuantityLimitMinGreaterThanOneMaxGreaterThanOneAndMinEqualMaxLabel:
            '${selectedQuantity} of ${maximum} selected',
    }

    return {
        ...defaultDisplayText,
        ...props.displayText,
    }
}
