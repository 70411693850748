export const vanillaAccordionInit = () => {
    const accordions = document.querySelectorAll('.accordion-list.partial .accordion-list__element-title');

    accordions.forEach(element => vanillaAccordionEventListener(element))
}

export const vanillaAccordionEventListener = (el:Element) => {
    el.addEventListener("click", event => {

        const mouseEvent = event as MouseEvent
        const currentTarget = mouseEvent.currentTarget as Element
        const bodyTarget:HTMLElement = currentTarget.parentElement.querySelector(".accordion-list__element-body")
        const isOpen:boolean = bodyTarget.classList.contains('open')

        if(isOpen) {
            bodyTarget.classList.remove('open');
            bodyTarget.style.maxHeight = "0px";
        }
        else {
            bodyTarget.classList.add('open');
            bodyTarget.style.maxHeight = bodyTarget.scrollHeight + "px";
        }
    })
}