import { FieldFormatType, GiftCardPaymentFormProps } from '@vista/omnichannel-components-ui'
import { err, log } from '../../utils'

import { GiftcardPaymentPropsFromBe } from '../../types'
import { defaultGiftCardPaymentFormProps } from '@vista/omnichannel-components-ui/lib/src/lib/components/gift-card-payment-form/types/props'

export function giftCardProps(props: GiftcardPaymentPropsFromBe): GiftCardPaymentFormProps {
    const validation: GiftCardPaymentFormProps['config']['validation'] = {
        cardNumber: { required: true, type: FieldFormatType.Number },
        pin: { required: false, type: FieldFormatType.Number },
    }
    const config = {
        ...defaultGiftCardPaymentFormProps.config,
        ...props.config,
        validation,
    }

    delete config.cardConfig
    delete config.pinConfig
    delete config.displayErrorsInline

    const displayText = {
        ...defaultGiftCardPaymentFormProps.displayText,
        ...props.displayText,
    }

    delete displayText.buttonSubmitLabel

    const assets = {
        ...defaultGiftCardPaymentFormProps.assets,
    }

    const events = {
        onBeforeSubmit: () => {
            log('onBeforeSubmit')
        },
        onAfterSuccessfulSubmit: () => {
            log('onAfterSuccessfulSubmit')
        },
        onAfterFailedSubmit: (error?: Error) => {
            err('onAfterFailedSubmit: ', error)
        },
        onCancelButtonClicked: () => {
            log('oncancelbuttonclicked')
        },
        onFormSubmittingStateChanged: (submitting: boolean) => {
            log('onFormSubmittingStateChanged: ', submitting)
        },
    }

    return {
        ...defaultGiftCardPaymentFormProps,
        displayText,
        config,
        events,
        assets,
    }
}
