import { EventPromotionProps } from '../../types'

export const EventPromotionComponent: React.FC<EventPromotionProps> = props => {
    const { title, description, image, links } = props.displayText

    const setDisplayGrid = image.desktop ? 'grid' : 'block'

    return (
        <div className="event-promo-container width--normal padding--add">
            <div className="inner-container" style={{ display: setDisplayGrid }}>
                <div className="event-promo-content">
                    <h2 className="event-promo-title">{title}</h2>
                    <p>{description}</p>
                    <div className="links-container">
                        {links.map(link => {
                            return (
                                <a className="promo-link btn--pink" href={link.url} target={link.target}>
                                    {link.name}
                                </a>
                            )
                        })}
                    </div>
                </div>
                {image.desktop && (
                    <div className="promo-image-container">
                        <picture>
                            <source srcSet={image.desktop} media="(min-width: 1200px)" />
                            <source srcSet={image.tablet} media="(min-width: 768px)" />
                            <img className="event-promo-image" src={image.mobile} alt="event promo image" />
                        </picture>
                    </div>
                )}
            </div>
        </div>
    )
}
