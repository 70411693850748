import { AddLoggedMemberToDataLayer, initReactComponent, } from '../../../utils'
import {getMember, isMemberSignedIn, loadMember} from '@vista/omnichannel-components-domain'
import { useEffect, useState } from 'react'

export const GtmMemberLoggedInLoader = (): void => {
    initReactComponent<any>({
        Component: () => <GtmMemberLoggedInComponent />,
        id: 'gtm-member-logged-in',
        props: {},
    })
}

export const GtmMemberLoggedInComponent: React.FC = () => {
    const [hasMemberLoaded, setMemberLoaded] = useState<boolean>()
    
    AddLoggedMemberToDataLayer()
    useEffect(() => {
        (async () => {
            if (!getMember() && isMemberSignedIn()) {
                await loadMember()
                //force component to remount
                setMemberLoaded(true)
            }
        })()
    }, [])
    return null
}