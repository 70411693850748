import { getJourneyById, loadJourneyById } from '@vista/omnichannel-components-domain'

import { MemberJourneyListProps } from '@vista/omnichannel-components-ui'
import { MemberJourneyListPropsFromBe } from '../../../../types'
import { UrlFactory } from '../../../../utils'

export const events = (props: MemberJourneyListPropsFromBe): MemberJourneyListProps['events'] => {
    return {
        onViewJourneyButtonClicked: async (journeyId: string) => {
            await loadJourneyById(journeyId)
            const orderId = getJourneyById(journeyId).orders[0].id
            const url = new UrlFactory(props.workflow)
            window.location.href = url.getTicketingOrderSummaryUrl(orderId)
        },
        onNoJourneysActionButtonClicked: () => {
            window.location.assign('/films/')
        },
    }
}

export const assets: MemberJourneyListProps['assets'] = {
    ticketsIconName: 'curzon-ticket',
    foodAndDrinkIconName: 'curzon-fnb',
    noJourneysIconName: 'curzon-ticket',
    failedToLoadJourneysIconName: 'curzon-ticket',
}

export const cdnOptions: MemberJourneyListProps['cdnOptions'] = {
    filmThumbnailImage: { width: 200 },
    itemImage: { width: 200 },
}

export const defaultDisplayText: MemberJourneyListProps['displayText'] = {
    foodAndDrinkJourneyTitle: 'Food & Drink Order',
    multiShowtimeJourneyTitleFormat: '${firstFilmTitle} + ${additionalShowtimeCount} others',
    nextGroupLabel: 'Up Next',
    itemOnlyGroupLabel: 'Food & Drink',
    lastWeekGroupLabel: 'Last Week',
    monthGroupFormat: '${date|d|MMMM YYYY}',
    siteNameLabel: 'Cinema',
    timeLabel: 'Time',
    timeFormat: '${startTime|d|HH:mm}',
    dateLabel: 'Date',
    dateFormat: '${startTime|d|MMMM DD, YYYY}',
    refundedTagLabel: 'Refunded',
    quantitySummaryFormat: '${quantity} x',
    viewTicketJourneyButtonLabel: 'View Ticket',
    viewFoodAndDrinkJourneyButtonLabel: 'View Order',
    loadMoreButtonLabel: 'Load more',
    noActiveJourneysTitle: "You don't have any upcoming bookings.",
    noActiveJourneysMessage: 'Bookings for showtimes in the future will be shown here.',
    noRecentJourneysTitle: "You don't have any recent bookings.",
    noRecentJourneysMessage: 'Past bookings will be shown here.',
    noJourneysActionButtonLabel: 'Browse films',
    failedToLoadMoreJourneysMessage: 'Failed to load more journeys.',
    failedToLoadDataTitle: 'Sorry, something went wrong.',
    failedToLoadDataMessage: 'Refresh your browser to try loading your transactions again.',
}
