import React from 'react'

export type ImageType = {
    desktopCropUrl: string
    tabletCropUrl: string
    mobileCropUrl: string
}

export type CmsImageType = {
    containerClass?: string
    imgClass?: string
    image: ImageType
    alt?: string
}

const CmsImage = (props: CmsImageType) => {
    const { image, containerClass = '', imgClass = '', alt = '' } = props
    return (
        <picture className={containerClass}>
            {image.mobileCropUrl && <source media="(max-width:768px)" srcSet={image.mobileCropUrl} />}
            {image.tabletCropUrl && <source media="(max-width:1024px)" srcSet={image.tabletCropUrl} />}

            <img className={imgClass} src={image.desktopCropUrl} alt={alt} />
        </picture>
    )
}

export default CmsImage
