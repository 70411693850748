import React, { useEffect, useState } from 'react'
import { browsingDomainStore, getSelectedSites } from '@vista/omnichannel-components-domain'
import { ModalSitePickerPropsType, ModalSitePickerEvents } from '../../types/modalSitePicker'
import { ModalSitePickerComponent } from '.'
import {observer} from 'mobx-react'

const storageKey = 'cinema-tooltip-expiry'

export const GlobalSitePickerButtonComponent: React.FC<ModalSitePickerPropsType> = observer((props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onClickHandler = () => setIsOpen(!isOpen)
    const selectedSiteCounter = browsingDomainStore.data.selectedSiteIds.length

    const modalSitePickerEvents: ModalSitePickerEvents = {
        onClose: onClickHandler,
        onSitesChanged: () => {
            if (getSelectedSites().length > 0) {
                document.querySelector(".main-nav").classList.remove("has-tooltip")
            }
        },
    }

    const isOpenClass = isOpen ? 'isOpen' : 'isClosed'

    const fullProps: ModalSitePickerPropsType = {
        displayText: props.displayText,
        sitePickerComponentProps: props.sitePickerComponentProps,
        show: isOpen,
        events: modalSitePickerEvents,
        className: `modal__site-picker global ${isOpenClass}`
    }

    const isSiteSelected = getSelectedSites().length > 0

    useEffect(() => {
        
        const isTooltipShowing:boolean = (!isSiteSelected && ShouldTooltipShow())
        if(isTooltipShowing) {
            document.querySelector(".main-nav").classList.add("has-tooltip")
        }

    }, [isSiteSelected ])

    const SitePickerIcon = () => {
        return (
            <>
                <svg className="c-icon c-icon__map c-icon__round--grey">
                    <use xlinkHref="#c-icon_map" />
                </svg>
            </>
        )
    }

    return (
        <>
            <ModalSitePickerComponent {...fullProps} />
            <a className={`global-site-picker `} onClick={onClickHandler}>
                <SitePickerIcon/>
                {!!selectedSiteCounter && (
                    <span className="global-site-picker__selected-sites">{selectedSiteCounter}</span>
                )}
            </a>
        </>
    )
    
})

const ShouldTooltipShow = (): boolean => {
    const now = new Date()

    if (localStorage.getItem(storageKey)) {
        const overrideExpiryDate = new Date(localStorage.getItem(storageKey))
        const isExpired = now >= overrideExpiryDate

        if (isExpired) localStorage.removeItem(storageKey)

        return isExpired
    } else {
        return true
    }
}

export default GlobalSitePickerButtonComponent


