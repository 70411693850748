
export function getLocalStorage<T>(key: string): T {
    const item = localStorage.getItem(key)
    return item ? (JSON.parse(item) as T) : null
}

export function setLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
}

export function removeLocalStorage(key:string): void {
    localStorage.removeItem(key)
}

