import { PromoEventType } from '../../../../types'
import { SwiperReactFacadeSlide } from '../..'
import React from 'react'
import { getFeCroppedImage } from '../../../../utils'

interface PromotedEventsSlideProps extends PromoEventType {
    styleObject?:React.CSSProperties
}

export const Slide: React.FC<PromotedEventsSlideProps> = props => {
    const {styleObject} = props

    const croppedImageSrc = () => {
        if (props.image.imageCropDetails && props.image.imageCropDetails.src) {
            return getFeCroppedImage(props.image.imageCropDetails.src, 450)
        }

        return ''
    }

    return (
        <SwiperReactFacadeSlide>
            <a
                className="event-slide"
                href={props?.ctaLink?.url}
                target={props?.ctaLink?.target}
                style={styleObject}
            >
                {props.startDate && <div className="event-slide__header-date" style={styleObject}>
                    <span className="event-slide__header-date-from">from</span>
                    <span className="event-slide__header-date-date">{props.startDate}</span>
                </div>}

                { props.image.imageCropDetails &&
                    <img className="event-slide__image" src={croppedImageSrc()} alt={props.headline} />
                }
                
                { !props.image.imageCropDetails &&
                    <img className="event-slide__image" alt="image not found" />
                }

                <div className="event-slide__details">
                    <h4>{props.subHeadline}</h4>
                    <h3>{props.headline}</h3>
                    <div>{props.location}</div>
                </div>
            </a>
        </SwiperReactFacadeSlide>
    )
}