export function tagRedirect() {
    const tagList = Array.from(document.getElementsByClassName('article-page-content__tag-label'))

    tagList.map(tag => {
        const pathAfterDomain = window.location.pathname.split('/')[1]

        const tagQuery = encodeURIComponent((tag as any).innerText)

        tag.addEventListener('click', () => {
            window.location.assign(`/${pathAfterDomain}/?tag=${tagQuery}`)
        })
    })
}
