import { selectedSeatsProps, showtimeSummaryProps } from './props'

import { ShowtimeSummaryComponent as OccShowtimeSummaryComponent } from '@vista/omnichannel-components-ui'
import React from 'react'
import { ShowtimeSummaryPropsPropsFromBe } from '../../types'

export const SelectedSeatsComponent: React.FC<ShowtimeSummaryPropsPropsFromBe> = props => {

    const showtimeSummaryComponentProps = selectedSeatsProps(props)

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.className

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.config.showFilmPoster

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.workflow
    
    return (
        <div className="showtime-summary-details__seats-only">
            <OccShowtimeSummaryComponent {...showtimeSummaryComponentProps} />
        </div>
    )
}

