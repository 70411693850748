import { Film, Site } from '@vista-digital/ocapi-types/v1'
import { I18N } from '@vista/i18n'
import { WorkflowData } from '../initialise/types'

export class UrlFactory {
    workflowData: WorkflowData

    constructor(workflowData: WorkflowData) {
        this.workflowData = workflowData
    }

    getFilmDetailsUrl(film: Film): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.browsingUrls.filmDetailsUrl, {
            filmTitle: this.urlFriendly(i18n.translatable(film.title)),
            filmId: film.id,
        })
    }

    getFilmDetailsUrlWithoutFilm(title: string, id: string): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.browsingUrls.filmDetailsUrl, {
            filmTitle: this.urlFriendly(title),
            filmId: id,
        })
    }

    getCinemaDetailsUrl(site: Site): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.browsingUrls.cinemaDetailsUrl, {
            siteId: site.id,
        })
    }

    getCinemaDetailsUrlByFilm(site: Site, film: Film): string {
        const cinemaDetailsUrl = this.getCinemaDetailsUrl(site)
        return cinemaDetailsUrl + '?filmId=' + film.id
    }

    getCinemaDetailsUrlByFilmAndDate(site: Site, film: Film, screeningDate: string): string {
        const cinemaDetailsUrl = this.getCinemaDetailsUrlByFilm(site, film)
        return cinemaDetailsUrl + '&screeningDate=' + screeningDate
    }

    getSeatPickerDetailsUrl(showtimeId: string, index?: number): string {
        const i18n = new I18N()

        return i18n.template(this.workflowData.ticketingWorkflow.pages[index || 0].url, {
            showtimeId: showtimeId,
        })
    }

    getTicketPickerDetailsUrl(): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.ticketingWorkflow.pages[1].url, {
            showtimeId: this.workflowData.ticketingWorkflow.showtimeId,
        })
    }

    getTicketingOrderSummaryUrl(orderId: string): string {
        const i18n = new I18N()
        return i18n.template(
            this.workflowData.ticketingWorkflow.pages[this.workflowData.ticketingWorkflow.pages.length - 1].url,
            {
                orderId,
            }
        )
    }

    getTicketingWorkflowUrl(idx: number, orderId?: string): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.ticketingWorkflow.pages[idx].url, {
            showtimeId: this.workflowData.ticketingWorkflow.showtimeId,
            orderId: orderId ?? '',
        })
    }

    getMembershipSubscriptionWorkflowUrl(idx: number, packageName?: string): string {
        const i18n = new I18N()
        return i18n.template(this.workflowData.membershipSubscriptionWorkflow.pages[idx].url, {
            package: packageName ?? this.workflowData.membershipSubscriptionWorkflow.package ?? '',
        })
    }

    getCurrentWorkflowUrl(idx: number, id?: string, packageName?: string): string {
        if (this.workflowData.workflowType == 'membershipSubscriptionWorkflow')
            return this.getMembershipSubscriptionWorkflowUrl(idx, packageName)
        else if (this.workflowData.workflowType == 'ticketingWorkflow') return this.getTicketingWorkflowUrl(idx, id)
    }

    getEventDetailsUrl(eventName: string, eventId: string): string {
        // Curzon seem to use the 'Promoted events carousel' on their home page currently - seems like duplicate component?

        return `/events/${this.urlFriendly(eventName)}/${eventId}`
    }

    urlFriendly(urlInput: string): string {
        //Takes a string and returns it in a url-compatible format
        return urlInput
            .replace(/\s+/g, '-')
            .replace(/-{2,}/g, '-')
            .replace(/[^a-zA-Z0-9-]/g, '')
            .toLowerCase()
    }
}
