import { TabData, Tabs, TabsDisplayType } from '@vista/omnichannel-components-ui'

import { useState } from 'react'

export interface TitleAndTabsPropsFromBe {
    displayText: {
        key: string
        titles: string[]
    }
}

export const TitleAndTabsComponent: React.FC<TitleAndTabsPropsFromBe> = props => {
    const { displayText } = props

    const tabItems = displayText.titles.map((item, index) => {
        return {
            label: item,
            value: index
        }
    })

    const [selectedTab, setSelectedTab] = useState<TabData>(tabItems[0])

    const handleOnTabSelected = (tab: TabData) => {

        const previous = document.getElementById(`${displayText.key}__wrapper`).getElementsByClassName(`tab-content-${selectedTab.value}`)[0]
        if (!previous.classList.contains('hide')) previous.classList.add('hide')

        const current = document.getElementById(`${displayText.key}__wrapper`).getElementsByClassName(`tab-content-${tab.value}`)[0]
        if (current.classList) current.classList.remove('hide')

        setSelectedTab(tab)
    }

    return (
        <Tabs centered={true} tabList={tabItems} onTabSelected={handleOnTabSelected} displayType={TabsDisplayType.Primary} selectedTab={selectedTab} />
    )
}

export default TitleAndTabsComponent