import {
    ButtonColor,
    ButtonIconPosition,
    ButtonSize,
    CustomerDetailsFormFieldName,
    CustomerDetailsFormProps,
} from '@vista/omnichannel-components-ui'
import { CustomerDetailsPropsFromBe, CustomerDetailsPropsFromBeDisplayText } from '../../types'

import { FieldFormatType } from '@vista/omnichannel-components-ui'
import {NestedPartial} from '@vista/omnichannel-components-domain'
import { defaultCustomerDetailsFormFieldPropsProvider } from '@vista/omnichannel-components-ui/lib/src/lib/components/customer-details-form-field/types/props'

export function customerDetailsFormProps(props: CustomerDetailsPropsFromBe): NestedPartial<CustomerDetailsFormProps> {
    const returnProps =  {
        ...props,
        displayText: displayText(props),
        config: config(),
        //events: events(props),
        assets: assets(),
    }

    delete returnProps.className
    delete returnProps.workflow

    /*@ts-ignore*/
    delete returnProps?.displayText?.bookingFeeLabel
    /*@ts-ignore*/
    delete returnProps?.displayText?.includedBookingFeeFormat
    /*@ts-ignore*/
    delete returnProps?.displayText?.totalCostLabel
    /*@ts-ignore*/
    delete returnProps?.displayText?.totalSavingsLabel

    return returnProps
}

// @TODO new data structure on BE.
function displayText(_props: CustomerDetailsPropsFromBe): CustomerDetailsFormProps['displayText'] {
    const defaultDisplayText = {
        errorFailedToSaveTitle: 'Failed to save details!',
        errorFailedToSaveMessage: 'Refresh your browser to try saving again.',
    }

    return {
        ...defaultDisplayText,
    }
}

function config(): CustomerDetailsFormProps['config'] {
    return {
        submitButton: {
            show: false,
            size: ButtonSize.Small,
            color: ButtonColor.Primary,
            iconPosition: ButtonIconPosition.Right,
        },
        cancelButton: {
            show: false,
            size: ButtonSize.Small,
            color: ButtonColor.Secondary,
            iconPosition: ButtonIconPosition.Right,
        },
        validation: {
            email: {
                required: true,
                type: FieldFormatType.Email,
            },
            familyName: {
                required: true,
                type: FieldFormatType.Text,
            },
            givenName: {
                required: true,
                type: FieldFormatType.Text,
            },
            phoneNumber: {
                required: false,
                type: FieldFormatType.Text,
            },
        },
    }
}

export function fieldDisplayText(
    fieldType: CustomerDetailsFormFieldName,
    displayText: CustomerDetailsPropsFromBeDisplayText
) {
    const model = defaultCustomerDetailsFormFieldPropsProvider({ name: fieldType }).displayText

    switch (fieldType) {
        case CustomerDetailsFormFieldName.Email:
            if (displayText.fieldEmailLabel) model.placeholder = displayText.fieldEmailLabel
            if (displayText.errorEmailRequiredMessage) model.requiredMessage = displayText.errorEmailRequiredMessage
            if (displayText.errorEmailFormatMessage) model.formatMessage = displayText.errorEmailFormatMessage
            if (displayText.errorEmailLengthMessage) model.lengthMessage = displayText.errorEmailLengthMessage
            break
        case CustomerDetailsFormFieldName.FamilyName:
            if (displayText.fieldFamilyNameLabel) model.placeholder = displayText.fieldFamilyNameLabel
            if (displayText.errorFamilyNameRequiredMessage)
                model.requiredMessage = displayText.errorFamilyNameRequiredMessage
            if (displayText.errorFamilyNameFormatMessage) model.formatMessage = displayText.errorFamilyNameFormatMessage
            if (displayText.errorFamilyNameLengthMessage) model.lengthMessage = displayText.errorFamilyNameLengthMessage
            break
        case CustomerDetailsFormFieldName.GivenName:
            if (displayText.fieldGivenNameLabel) model.placeholder = displayText.fieldGivenNameLabel
            if (displayText.errorGivenNameRequiredMessage)
                model.requiredMessage = displayText.errorGivenNameRequiredMessage
            if (displayText.errorGivenNameFormatMessage) model.formatMessage = displayText.errorGivenNameFormatMessage
            if (displayText.errorGivenNameLengthMessage) model.lengthMessage = displayText.errorGivenNameLengthMessage
            break

        case CustomerDetailsFormFieldName.PhoneNumber:
            if (displayText.fieldPhoneNumberLabel) model.placeholder = displayText.fieldPhoneNumberLabel
            if (displayText.errorPhoneNumberRequiredMessage)
                model.requiredMessage = displayText.errorPhoneNumberRequiredMessage
            if (displayText.errorPhoneNumberFormatMessage)
                model.formatMessage = displayText.errorPhoneNumberFormatMessage
            if (displayText.errorPhoneNumberLengthMessage)
                model.lengthMessage = displayText.errorPhoneNumberLengthMessage
            break
    }

    return model
}

function assets(): CustomerDetailsFormProps['assets'] {
    return {}
}
