// import '@vista/omnichannel-components-ui/lib/js/vista.icons'

import { ComponentData, dataComponents, nullaryComponents } from './initialise'
import { InitData, InitialiseWidgets } from './initialise/types'
;(window as any).initialiseWidgets = (data: InitData): InitialiseWidgets => {
    const promises = [
        import('@vista/omnichannel-components-ui/lib/js/vista.icons'),
        import('./initialise/occInit'),
        import('./initialise/occContext'),
    ]

    Promise.all(promises).then(([icons, occ, contextData]) => {
        //clearEventCache()
        occ.default(data.occInititialiseData)
        contextData.default(data.occInititialiseData)
        dataComponents(data)
        nullaryComponents()
        updateAnchorTags()
    })
}

function updateAnchorTags() {
    const domain = window.location.origin
    let animationFrameId = null

    // Function to update anchor tags
    function updateAnchorTags() {
        const anchors = document.querySelectorAll('a')
        anchors.forEach(anchor => {
            const href = anchor.getAttribute('href')
            if (href && href.startsWith('/')) {
                anchor.setAttribute('href', `${domain}${href}`)
            }
        })
    }

    // Use requestAnimationFrame to schedule updates
    function animate() {
        updateAnchorTags()
        animationFrameId = requestAnimationFrame(animate)
    }

    // Call the animation function initially
    animate()

    // Clear the animation frame after a certain time (e.g., 30s)
    setTimeout(() => {
        cancelAnimationFrame(animationFrameId)
    }, 30000)
}
