import React, { Children, ReactNode, useState } from 'react'
import { TabData, Tabs, TabsDisplayType } from '@vista/omnichannel-components-ui'

interface ITabsComponent {
    children?: ReactNode
    labels: string[]
}

/**
 * @example
    <TabsComponent labels={["label for tab one", "tab two", "label three"]} >
        <YourComponentDisplayedInTabOne />
        <YourComponentDisplayedInTabTwo />
        <YourComponentDisplayedInTabThree />
    </TabsComponent>
 */
export const TabsComponent: React.FC<ITabsComponent> = props => {
    const tabList: TabData[] = props.labels.map((label, value) => ({ label, value }))

    const [tabIdx, setTabIdx] = useState<number>(0)
    const handleOnTabSelected = (tab: TabData) => setTabIdx(+tab.value)

    const Childrens: JSX.Element[] = Children.toArray(props.children).map((children, idx) =>
        // prettier-ignore
        idx === tabIdx
            // key={idx} - as they are always associated with same idx, we will not get performance penalty
            ? <div key={idx} className="show">{children}</div>
            : <div key={idx} className="hide">{children}</div>
    )

    return (
        <>
            <Tabs centered={true} tabList={tabList} onTabSelected={handleOnTabSelected} selectedTab={tabList[tabIdx]} displayType={TabsDisplayType.Primary} />
            {Childrens}
        </>
    )
}
