import { HeadingLevel, Modal } from '@vista/omnichannel-components-ui'
import { ISignIn, TicketPickerSignInProps } from '../../types'
import React, { useState } from 'react'

import { SignIn } from '.'

export const TicketPickerSignInComponent: React.FC<
    TicketPickerSignInProps & { guestHandler: VoidFunction }
> = props => {
    const formProps: ISignIn = {
        form: props.form,
        displayText: {
            forgotPasswordName: props.modal.displayText.forgotPasswordName
        },
        events: {
            signInHandler: props.signInHandler,
        },
    }

    const [modalOpen, setModalOpen] = useState(false)
    const closeHandler = () => setModalOpen(false)

    return (
        <div className="ticket-picker-signin">
            <div className="ticket-picker-signin__showtime-summary">
                <h3 className="showtime-summary-heading">{props.customLabels.showtimeSummaryTitle}</h3> {props.children}
            </div>
            <div className="ticket-picker-signin__guest">
                <h3 className="signin-guest-heading">{props.guest.displayText.guestSignInTitle}</h3>
                <p>{props.guest.displayText.guestSignInText}</p>
                <button className="btn--black ticket-picker-signin__guest-btn" onClick={props.guestHandler}>
                    {props.guest.displayText.continueAsGuestCTAText}
                </button>
                <button className="btn--grey ticket-picker-signin__join-btn" onClick={() => setModalOpen(true)}>
                    {props.guest.displayText.joinNowCTAText}
                </button>
                <p>{props.guest.displayText.bookingFeeText}</p>
            </div>
            <div className="ticket-picker-signin__form">
                <SignIn {...formProps} />
            </div>

            <Modal
                show={modalOpen}
                onClose={closeHandler}
                className="modal__join-now"
                closeButtonIconName="c-icon_cross-black"
                heading={props.guest.displayText.modalTitle}
                headingLevel={HeadingLevel.H3}
            >
                <p>{props.guest.displayText.modalText}</p>
                <a
                    className="btn--black ticket-picker-signin__guest-btn"
                    href={props.guest.config.joinNowCTALink}
                    onClick={props.guestHandler}
                >
                    {props.guest.displayText.modalGoToMembershipLabel}
                </a>
                <button className="btn--grey ticket-picker-signin__join-btn" onClick={closeHandler}>
                    {props.guest.displayText.modalCancelButtonLabel}
                </button>
            </Modal>
        </div>
    )
}
