import { ReactSingleComponentsType, ReactWrapperComponentsNamesType } from './types'
import { lazy } from 'react'

// Components
import { FilmListComponent } from './../components/filmList/FilmListComponent'
import { FooterNewsletterComponent } from './../components/footerNewsletter/FooterNewsletter'
import { GlobalNotificationBannerComponent } from './../components/notificationBanner/GlobalNotificationBanner'
import { GlobalSitePickerButtonComponent } from './../components/sitePicker/GlobalSitePickerButtonComponent'
import { ContentAccordionComponent } from './../components/contentAccordion/ContentAccordionComponent'
import { PromotedEventsComponent } from './../components/carousel/carouselComponents/promotedEvents/PromotedEventsCarouselComponent'
import { BookNowComponent } from './../components/bookNow/BookNowComponent'
import { TitleAndTabsComponent } from './../components/titleAndTabs/TitleAndTabsComponent'
import { CinemaTooltipComponent } from './../components/cinemaTooltip/CinemaTooltipComponent'
import { PopUpComponent } from './../components/popUp/PopUpComponent'

export const ReactSingleComponents: ReactSingleComponentsType = {
    ArticleComponent: lazy(() => import('./../components/articleList/ArticleList')), //ArticleListComponent,
    CinemaDetailsSpecificBanner: lazy(() =>
        import('./../components/notificationBanner/CinemaSpecificNotificationBanner')
    ),
    DateShowtimePicker: lazy(() => import('./../components/dateShowtimePicker/DateShowtimePickerComponent')),
    EventCarousel: lazy(() => import('./../components/carousel/carouselComponents/events/eventCarouselComponent')),
    EventDetails: lazy(() => import('./../components/eventDetails/EventDetails')),
    EventShowtimePicker: lazy(() => import('./../components/eventShowtimePicker/EventShowtimePickerComponent')),
    FilmCarousel: lazy(() =>
        import('./../components/carousel/carouselComponents/relatedFilms/RelatedFilmsCarouselComponent')
    ),
    FilmDetailsEventCarousel: lazy(() =>
        import('./../components/carousel/carouselComponents/events/eventCarouselComponent')
    ),
    MembershipSubscriptionHeader: lazy(() =>
        import('./../components/membershipSubscriptionHeader/MembershipSubscriptionHeader')
    ),
    PrivateInquiryForm: lazy(() => import('./../components/privateInquiryForm/PrivateInquiryForm')),
    PrivateInquiryReceived: lazy(() => import('./../components/privateInquiryForm/PrivateInquiryReceived')),
    TabbedEventCarousel: lazy(() =>
        import('./../components/carousel/carouselComponents/tabbedEvents/tabbedEventsComponent')
    ),
    VenueFinderSearch: lazy(() => import('./../components/venueFinder/VenueFinderComponent')),

    // Import these components directly as they're used on the homepage
    FilmList: FilmListComponent,
    FooterNewsletter: FooterNewsletterComponent,
    GlobalNotificationBanner: GlobalNotificationBannerComponent,
    GlobalSitePicker: GlobalSitePickerButtonComponent,
    InformationPanel: ContentAccordionComponent,
    PromotedEvents: PromotedEventsComponent,
    QuickNavigator: BookNowComponent,
    TitleTabs: TitleAndTabsComponent,
    CinemaTooltip: CinemaTooltipComponent,
    Popup: PopUpComponent,
}

// ---- Pages ----

// usercomponent
const UserComponent = lazy(() => import('./../components/member/Member'))

// membershipFlow
const AccountCreatedPage = lazy(() => import('./../pages/membershipFlow/AccountCreated'))
const RenewalsPage = lazy(() => import('./../pages/membershipFlow/Renewals'))
const MembershipVoucherPage = lazy(() => import('./../pages/membershipFlow/MembershipVoucher'))
const PaymentPage = lazy(() => import('./../pages/membershipFlow/Payment'))
const PhotoUploadPage = lazy(() => import('./../pages/membershipFlow/PhotoUpload'))
const ReviewDetailsPage = lazy(() => import('./../pages/membershipFlow/ReviewDetails'))
const PersonalDetailsPage = lazy(() => import('./../pages/membershipFlow/PersonalDetails'))
const PackageSelectionPage = lazy(() => import('./../pages/membershipFlow/PackageSelection'))

// pages
const CinemaDetailsPage = lazy(() => import('./../pages/CinemaDetails'))
const CustomerDetailsFormPage = lazy(() => import('./../pages/CustomerDetailsForm'))
const EventDetailsPage = lazy(() => import('./../pages/EventDetails'))
const FilmDetailsPage = lazy(() => import('./../pages/FilmDetails'))
const GiftShopConfirmationPage = lazy(() => import('./../pages/GiftShopConfirmation'))
const GiftShopPage = lazy(() => import('./../pages/GiftShop'))
const GiftShopPaymentPage = lazy(() => import('./../pages/GiftShopPayment'))
const MembershipSalesPage = lazy(() => import('./../pages/MembershipSales'))
const MemberPasswordResetPage = lazy(() => import('./../pages/MemberPasswordReset'))
const GenericPage = lazy(() => import('./../pages/GenericPage'))

// ticketing
const CustomerDetailsPage = lazy(() => import('./../pages/ticketing/CustomerDetails'))
const FoodAndBevaregePage = lazy(() => import('./../pages/ticketing/FoodAndBevarage'))
const OrderPaymentPage = lazy(() => import('./../pages/ticketing/OrderPayment'))
const OrderSummaryPage = lazy(() => import('./../pages/ticketing/OrderSummary'))
const SeatPickerPage = lazy(() => import('./../pages/ticketing/SeatPicker'))
const TicketPickerPage = lazy(() => import('./../pages/ticketing/TicketPicker'))

// memberacc
const MemberAccBookingsPage = lazy(() => import('./../pages/memberAcc/MemberAccBookings'))
const MemberAccCardWalletPage = lazy(() => import('./../pages/memberAcc/MemberAccCardWallet'))
const MemberAccDetailsPage = lazy(() => import('./../pages/memberAcc/MemberAccDetails'))
const MemberAccMembershipPage = lazy(() => import('./../pages/memberAcc/MemberAccMembership'))
const MemberAccOverviewPage = lazy(() => import('./../pages/memberAcc/MemberAccOverview'))
const MemberAccPreferencesPage = lazy(() => import('./../pages/memberAcc/MemberAccPreferences'))
const MemberAccRewardPage = lazy(() => import('./../pages/memberAcc/MemberAccRewards'))
const MemberAccWatchlistPage = lazy(() => import('./../pages/memberAcc/MemberAccWatchlist'))

// components in sidebar and context. They are on every memberAcc page.
const memberAccCommonComponents = [
    'MemberContext',
    'MemberAccountMenu',
    'MemberViewCardButton',
    'MemberCardNumber',
    'MemberPointsBalance',
    'MemberSubscriptionsDetails',
]

export const ComponentsBothWrapAndSingle = ['MembershipSubscriptionHeader']

// prettier-ignore
export const ReactWrapperComponentsNames: ReactWrapperComponentsNamesType[] = [
    // booking flow {{{
    { id:'SeatPickerWrap',      componentWrap: SeatPickerPage,       components: [ 'GuestSignIn', 'ModalSignIn', 'SignInForm', 'SeatPicker', 'ShowtimeContext', 'ShowtimeSummary', 'WorkflowNavigation','TicketingCustomLabels','PreviewSignIn' ] },
    { id:'TicketPickerWrap',    componentWrap: TicketPickerPage,     components: [ 'OrderExpiryCountdown', 'ShowtimeContext', 'ShowtimeSummary', 'ThreeDGlassesPicker', 'WorkflowNavigation', 'TicketPicker', 'TicketRedemptionCodeForm', 'TicketRedemptionCardPicker', 'OrderTotals' ] },
    { id:'FoodAndBeverageWrap', componentWrap: FoodAndBevaregePage,  components: [ 'OrderExpiryCountdown', 'OrderCart', 'OrderItemPicker', 'WorkflowNavigation' ] },
    { id:'CustomerDetailsWrap', componentWrap: CustomerDetailsPage,  components: [ 'OrderExpiryCountdown', 'ShowtimeContext', 'CustomerDetailsForm', 'WorkflowNavigation', 'ShowtimeSummary', 'OrderCart', 'CustomerDetailsHeadersComponent'] },
    { id:'OrderPaymentWrap',    componentWrap: OrderPaymentPage,     components: [ 'GiftCardPaymentForm', 'ShowtimeContext', 'OrderExpiryCountdown', 'OrderPaymentForm', 'WorkflowNavigation'] },
    { id:'OrderSummaryWrap',    componentWrap: OrderSummaryPage,     components: [ 'JourneyContext', 'JourneySummary','JourneyDetails', 'ShowtimeSummary' ] },
    // booking flow }}}
    { id:'CinemaDetailsWrap',   componentWrap: CinemaDetailsPage,    components: [ 'DateShowtimePicker', 'EventShowtimePicker', 'InformationPanel', 'MenuPanel' ] },
    { id:'FilmDetailsWrap',     componentWrap: FilmDetailsPage,      components: [ 'DateShowtimePicker', 'FilmDetails','SitePickerForDateShowtimePicker', 'FilmCarousel', 'WatchAtHomeComponent', 'FilmDetailsEventCarousel','EventPromotion','SuppressShowtimePickerComponent' ] },
    { id:'EventDetailsWrap',    componentWrap: EventDetailsPage,     components: [ 'EventDetails', 'EventShowtimePicker' ] },
    { id:'signinWrap',          componentWrap: UserComponent,        components: [ 'SignInForm', 'ModalSignIn', 'GuestSignIn' ] },
    { id:'MembershipSalesWrap', componentWrap: MembershipSalesPage,  components: [ 'MembershipSalesThreeColumns' ] },
    
    { id:'GiftShopWrap', componentWrap: GiftShopPage,  components: [ 'GiftShopCustomDetails', 'GiftCardChecker', 'GiftShopItemPicker', 'WorkflowNavigation'] },   
    { id:'GiftShopConfirmationWrap', componentWrap: GiftShopConfirmationPage,  components: ['GiftCardConfirmation','WorkflowNavigation'] },
    { id:'CustomerDetailsFormWrap', componentWrap: CustomerDetailsFormPage,  components: ['CustomerDetailsForm', 'JourneyDetails','WorkflowNavigation'] },
    { id:'GiftShopPaymentWrap', componentWrap: GiftShopPaymentPage,  components: ['OrderPaymentForm','WorkflowNavigation'] },
    
    // membership flow {{{
    { id:'PersonalDetailsWrap', componentWrap: PersonalDetailsPage,  components: [ 'SignUpForm', 'WorkflowNavigation', 'SignUpFormFields' ] },
    { id:'ReviewDetailsWrap', componentWrap: ReviewDetailsPage,  components: [ 'MemberDetailsForm','MemberReviewDetails', 'WorkflowNavigation'] },
    { id:'PackageSelectionWrap',componentWrap: PackageSelectionPage, components: [ 'SubscriptionItemPicker', 'WorkflowNavigation','MembershipAddOns' ] },
    { id:'PhotoUploadWrap', componentWrap: PhotoUploadPage,  components: [ 'MemberPhotoUploader', 'WorkflowNavigation','MembershipUploadPhotoFields' ] },
    { id:'PaymentWrap', componentWrap: PaymentPage,  components: [ 'OrderPaymentForm', 'WorkflowNavigation','PaymentDetails' ] },
    { id:'AccountCreatedWrap', componentWrap: AccountCreatedPage,  components: [ 'MembershipSubscriptionHeader','MemberCard', 'MemberAccountCreated' ] },
    { id:'MembershipRenewalWrap', componentWrap: RenewalsPage,  components: [ 'MembershipSalesThreeColumns', 'WorkflowNavigation', 'MembershipRenewalsDetails' ] },
    { id:'VoucherWrap', componentWrap: MembershipVoucherPage,  components: [ 'WorkflowNavigation', 'SubscriptionVoucherForm' ] },
    
    // membership flow }}}
    //
    // MemberAcc {{{
    { id:'MemberAccOverviewWrap',    componentWrap: MemberAccOverviewPage,    components: [...memberAccCommonComponents, 'MemberRewardList', 'MemberRewardViewShowtimes', 'MemberAccountOverviewDetails', 'HeadingsSubheadingsAccordions', 'MemberJourneyList'] },
    { id:'MemberAccBookingsWrap',    componentWrap: MemberAccBookingsPage,    components: [...memberAccCommonComponents, 'BookingAndTransactionsDetails', 'TransactionComponent', 'JourneyComponent', 'MemberJourneyList'] },
    { id:'MemberAccWatchlistWrap',   componentWrap: MemberAccWatchlistPage,   components: [...memberAccCommonComponents, 'Watchlist'] },
    { id:'MemberAccRewardWrap',      componentWrap: MemberAccRewardPage,      components: [...memberAccCommonComponents, 'MemberRewardList', 'MemberRewardViewShowtimes'] },
    { id:'MemberAccMembershipWrap',  componentWrap: MemberAccMembershipPage,  components: [...memberAccCommonComponents, 'MemberSubscription', 'HeadingsSubheadingsAccordions','MemberSubscriptionsDetails', 'MemberBannerLinks' ] },
    { id:'MemberAccCardWalletWrap',  componentWrap: MemberAccCardWalletPage,  components: [...memberAccCommonComponents, 'CardWallet' ] },
    { id:'MemberAccDetailsWrap',     componentWrap: MemberAccDetailsPage,     components: [...memberAccCommonComponents, 'MemberDetailsForm', 'EmailMemberCredentialForm', 'PasswordMemberCredentialForm', 'MemberPhotoUploader', 'MemberCustomPersonalDetails' ] },
    { id:'MemberAccPreferencesWrap', componentWrap: MemberAccPreferencesPage, components: [...memberAccCommonComponents, 'CommunicationPreferences','FavoriteCinemas'] },
    // MemberAcc }}}

    { id:'MemberPasswordResetWrap', componentWrap: MemberPasswordResetPage, components: ["PasswordResetCreateForm","PasswordResetCreateFormField"] },
    { id:'GenericPageWrap', componentWrap: GenericPage, components: ["DateShowtimePicker", "ShowtimePickerSettings"] },
]
