import React, { useEffect } from 'react'

import { SwiperReactFacadeProps } from '../../types'
import { swiperFacade } from './swiperFacade'
import { v4 as uuidv4 } from 'uuid'

export const SwiperReactFacade: React.FC<SwiperReactFacadeProps> = props => {
    const uuid = uuidv4()
    const options = props.options

    useEffect(() => {
        const element = document.getElementById(uuid) as HTMLElement
        swiperFacade({ element, options, hideArrows: !!props.hideArrows })
    }, [])

    // Workaround for ios not sizing the slides properly if there are 2,3 or 4 of them
    const iosClass = props.children.length > 1 && props.children.length < 5 ? 'iosWidth' : ''

    const wrapperCls = `${props.className} swiper-carousel`
    return (
        <div id={uuid} className={wrapperCls}>
            <div className="swiper-carousel__container swiper-container padding--no">
                <div className={`swiper-carousel__wrapper swiper-wrapper ${iosClass}`}>
                    <>{props.children}</>
                </div>
                <div className="swiper-carousel__pagination swiper-pagination"></div>
            </div>
            <div className="swiper-carousel__arrow-left swiper-button-prev">
                <svg className="c-icon c-icon__round--white carousel__arrow-left">
                    <use xlinkHref="#v-icon_arrow-left"></use>
                </svg>
            </div>
            <div className="swiper-carousel__arrow-right swiper-button-next">
                <svg className="c-icon c-icon__round--white carousel__arrow-right">
                    <use xlinkHref="#v-icon_arrow-right"></use>
                </svg>
            </div>
        </div>
    )
}

interface SwiperReactFacadeSlideProps {
    children?:any
}

export const SwiperReactFacadeSlide = (props:SwiperReactFacadeSlideProps) => {
    return <div className={`swiper-carousel__slide swiper-slide `}>{props.children}</div>
}

/**
 * @example
 * export const ExampleCarousel: React.FC<InterfaceProps> = props => {
 *     const settings = {
 *         // (...)
 *     }
 *
 *     return (
 *         <>
 *             <SwiperReactFacade options={settings} className="uniqclassname">
 *                     <SwiperReactFacadeSlide>
 *                         <div className="swiper-carousel__slide__img">
 *                             <img src="#" alt="Headline" />
 *                         </div>
 *                         <h3 className="swiper-carousel__slide__heading">Headline</h3>
 *                         <h4 className="swiper-carousel__slide__sub-heading">SubHeadline</h4>
 *                         <p>Sit suscipit laborum exercitationem nihil tenetur Nemo nihil neque adipisci</p>
 *                     </SwiperReactFacadeSlide>
 *                     <SwiperReactFacadeSlide>
 *                         <p>Sit suscipit laborum exercitationem nihil tenetur Nemo nihil neque adipisci</p>
 *                     </SwiperReactFacadeSlide>
 *                     <SwiperReactFacadeSlide>
 *                         <p>Sit suscipit laborum exercitationem nihil tenetur Nemo nihil neque adipisci</p>
 *                     </SwiperReactFacadeSlide>
 *             </SwiperReactFacade>
 *         </>
 *     )
 * }
 *
 * @optionaly Loader for nullaryComponents
 * export const ExampleCarouselLoader = () => {
 *     initReactComponent<any>({
 *         Component: () => <ExampleCarousel />,
 *         id: '5d9779a6-08ce-4cdc-a677-818c9536fb49',
 *         props: {},
 *     })
 * }
 *
 **/
