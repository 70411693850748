import cls from 'classnames'
import { BookingStepperStepProps, BookingStepperStepBem } from '../../types'

/** generate elements with modifiers, based on state of node */
export function BookingStepperStepBemClassNames(props: BookingStepperStepProps): BookingStepperStepBem {
    const block__step = props.blockCls + '__step'

    // prettier-ignore
    const block__stepWithMods = cls(block__step, {
        [block__step + '--complete']    : props.state.complete,
        [block__step + '--in-progress'] : props.state.inProgress,
        [block__step + '--incomplete']  : props.state.incomplete,
        [block__step + '--first']       : props.state.isFirst,
        [block__step + '--last']        : props.state.isLast,
    })

    const block__step__svg = block__step + '__svg'

    const block__step__svg__lineLeft = cls(block__step__svg + '__line-left', block__step__svg, {
        [block__step__svg + '--black']: props.state.complete || props.state.inProgress,
        [block__step__svg + '--grey']: props.state.incomplete,
        [block__step__svg + '--hide']: props.state.isFirst,
    })

    const block__step__svg__lineRight = cls(block__step__svg + '__line-right', block__step__svg, {
        [block__step__svg + '--black']: props.state.complete,
        [block__step__svg + '--grey']: props.state.incomplete || props.state.inProgress,
        [block__step__svg + '--hide']: props.state.isLast,
    })

    const block__step__svg__circle = cls(block__step__svg, {
        [block__step__svg + '--black']: props.state.complete || props.state.inProgress,
        [block__step__svg + '--grey']: props.state.incomplete,
    })

    const block__step__name = block__step + '__name'

    // prettier-ignore
    return {
        element   : block__stepWithMods,
        lineLeft  : block__step__svg__lineLeft,
        lineRight : block__step__svg__lineRight,
        circle    : block__step__svg__circle,
        name      : block__step__name,
    }
}
