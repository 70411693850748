import { OrderCartProps } from '@vista/omnichannel-components-ui'
import { OrderCartPropsFromBe } from '../../types'

export function orderCartProps(props: OrderCartPropsFromBe): OrderCartProps {
    const returnProps =  {
        displayText: displayText(props),
        config: config(),
        events: events(),
        assets: assets(),
        cdnOptions: cdnOptions(),
    }

    /*@ts-ignore*/
    delete returnProps?.displayText?.bookingFeeLabel
    /*@ts-ignore*/
    delete returnProps?.displayText?.includedBookingFeeFormat
    /*@ts-ignore*/
    delete returnProps?.displayText?.totalCostLabel
    /*@ts-ignore*/
    delete returnProps?.displayText?.totalSavingsLabel

    return returnProps
}

function displayText(props: OrderCartPropsFromBe): OrderCartProps['displayText'] {
    return {
        ...props.displayText,
    }
}

function config(): OrderCartProps['config'] {
    const defaultConfig = {
        actionButton: {
            show: false,
        },
        filmTitle: {
            show: false,
        },
        tickets: {
            show: true,
            showActionButton: false,
        },
        items: {
            show: true,
            showActionButton: false,
            showImage: true,
            showRemoveButton: true,
        },
        showBookingFee: true,
        showLabels: true,
        showEstimatedTaxLabel: true,
    }
    return {
        ...defaultConfig,
    }
}

function events(): OrderCartProps['events'] {
    return {}
}
function assets(): OrderCartProps['assets'] {
    return {
        actionButtonIconName: 'chevron-right',
        removeItemButtonIconName: 'trash',
    }
}
function cdnOptions(): OrderCartProps['cdnOptions'] {
    return {
        itemImage: { width: 120 },
    }
}
