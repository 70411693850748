import { SwiperFacadeProps } from './../../types'
import Swiper, { Navigation, Pagination, Autoplay, SwiperOptions } from 'swiper'
import { err } from './../../utils'

/**
 * swiperState wrapper for few common options.
 * Use it with spread operator
 *
 * @example
    const state = swiperState(3000)
    const options: SwiperOptions = {
        spaceBetween: 0,
        slidesPerView: 1,
        ...state.stop,
        breakpoints: {
            1200: {
                ...state.play,
            },
        },
    }
 */
export function swiperState(autoplayDelay=3000) { // eslint-disable-line
    const autoplay = {
        autoplay: {
            delay: autoplayDelay,
            disableOnInteraction: true,
            waitForTransition: true,
        }
    }

    const stop = {
        allowTouchMove: false,
        autoplay: false,
    }

    const play = {
        allowTouchMove: !stop.allowTouchMove,
        ...autoplay,
    }

    return {
        autoplay,
        play,
        stop,
    }
}

export function stopSwiper(options: SwiperOptions): SwiperOptions {   
    const { stop } = swiperState()
    return {
        ...options,
        breakpoints: {
            ...options.breakpoints,
            769: {
                ...options.breakpoints['769'],
                ...stop,
            },
            1200: {
                ...options.breakpoints['1200'],
                ...stop,
            },
        },
    }
}

export function showArrows(element: HTMLElement): void {
    element.querySelector<HTMLElement>('.swiper-button-next').style.visibility = 'visible'
    element.querySelector<HTMLElement>('.swiper-button-prev').style.visibility = 'visible'
}

/**
 * Instantiate and return Swiper
 *
 */
export const swiperFacade = ({ element, options, hideArrows }: SwiperFacadeProps): Swiper => {
    try {
        const d = new Date()
        const swiperContainer = element?.querySelector<HTMLElement>('.swiper-container')
        if(!swiperContainer) return
       
        Swiper.use([Navigation, Pagination, Autoplay])
        
        const swiper = new Swiper(swiperContainer, options)

        const nextBtn: HTMLElement = element.querySelector('.swiper-carousel__arrow-right')
        const prevBtn: HTMLElement = element.querySelector('.swiper-carousel__arrow-left')

        if(!nextBtn || !prevBtn) throw `Missing: "nextBtn" and/or "prevBtn" in ${element}`

        nextBtn.addEventListener('click', ()=>swiper.slideNext())
        prevBtn.addEventListener('click', ()=>swiper.slidePrev())

        if(!hideArrows) {
            nextBtn.style.visibility = 'visible'
            prevBtn.style.visibility = 'visible'
        }

        const ms = new Date().getTime() - d.getTime();
        console.debug(`Swiper init took ${ms}ms`)

        return swiper
    } catch(e) {
        err(e)
    }
}

/**
 * @example html:
    <div class="${className} swiper-carousel width--full">
        <div class="swiper-carousel__container swiper-container">
            <div class="swiper-carousel__wrapper swiper-wrapper">
                @foreach (var carouselItem in Model.Items)
                {
                <div class="swiper-carousel__slide swiper-slide">
                    <div class="swiper-carousel__slide__img"> <img src="#" alt="Headline"> </div>
                    <h3 class="swiper-carousel__slide__heading">Headline</h3>
                    <h4 class="swiper-carousel__slide__sub-heading">SubHeadline</h4>
                    <p>Sit suscipit laborum exercitationem nihil tenetur Nemo nihil neque adipisci</p>
                </div>
                }
            </div>
            <div class="swiper-carousel__pagination swiper-pagination"></div>
        </div>
        <div class="swiper-carousel__arrow-left swiper-button-prev">
            <svg class="c-icon c-icon__round--white carousel__arrow-left"><use xlink:href="#c-icon_arrow-left"></use></svg>
        </div>
        <div class="swiper-carousel__arrow-right swiper-button-next">
            <svg class="c-icon c-icon__round--white carousel__arrow-right"><use xlink:href="#c-icon_arrow-right"></use></svg>
        </div>
    </div>
 *
 * @example js
 * const swiper = swiperFacade({ element, options })
 */
