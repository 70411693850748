import { WorkflowNavigationPropsFromBe, WorkflowNavigationDisplayText } from '../../types'

export function displayText(props: WorkflowNavigationPropsFromBe): WorkflowNavigationDisplayText  {
    const defaultDisplayText = {
        nextLabel: 'Go next',
        previousLabel: 'Go back',
    }

    return {
        ...defaultDisplayText,
        ...props.displayText,
    }
}
