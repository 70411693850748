import React from 'react'
import {
    JourneyActionButtonAppleWalletDisplayText,
    JourneyActionButtonAppleWalletAssets,
    JourneyActionButtonProps,
    JourneyActionButtonType,
    JourneyActionButtonComponent,
} from '@vista/omnichannel-components-ui'

// eslint-disable-next-line @typescript-eslint/ban-types
export const AppleWallet: React.FC<{}> = () => {
    const displayText: JourneyActionButtonAppleWalletDisplayText = {
        failedToLoadPassMessage: 'Please try again later.',
        failedToLoadPassTitle: 'Failed to get your Apple Wallet pass.',
    }

    const assets: JourneyActionButtonAppleWalletAssets = {
        icon: {
            name: 'add-to-apple-wallet-button',
            viewBox: '0 0 110.739 35.016',
        },
    }

    const buttonProps: JourneyActionButtonProps = {
        type: JourneyActionButtonType.AppleWallet,
        assets,
        displayText,
    }
    const shouldAppleWalletRender: () => boolean = () => {
        // Snippet from Cinesa
        const isIOSdevice = navigator.userAgent.includes('iPhone' || 'iPad' || 'iPod')
        console.debug({ isIOSdevice })
        /**
         * The snippet const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); from vista-digital
         * occ repo does not work.
         * Major iOS browser agents have following unique signatures:
         *  - Chrome: CriOS. Reference: https://whatmyuseragent.com/browser/ci/chrome-mobile-ios/99
         *  - Opera: OPT. Reference: No reference. Found signature on portfowarded localhost outputting window.navigator.userAgent
         *  running on real iphone opera browser.
         *  - Opera: Mini. OPiOS. Reference: https://whatmyuseragent.com/browser/o1/opera-mini-ios
         *  - Opera GX: OPX. Reference: https://whatmyuseragent.com/browser/ox/opera-gx
         *  - Firefox: FxiOS. Reference: https://whatmyuseragent.com/browser/f1/firefox-mobile-ios/99
         *  - UCBrowser: UCBrowser. Reference: https://user-agents.net/browsers/uc-browser/platforms/ios
         *  - Edge: EdgiOS. Reference: https://user-agents.net/browsers/edge/platforms/ios
         *
         *  Brave has window.navigator.brave in navigator. https://community.brave.com/t/detect-if-user-is-using-brave-as-browser-when-navigates-on-my-website/473611.
         *  Verified this on portfowarded localhost outputting window.navigator.userAgent
         *  running on real iphone brave browser
         * **/
        const uniqueUserAgentSignatures = ['CriOS', 'OPT', 'OPiOS', 'OPX', 'FxiOS', 'UCBrowser', 'EdgiOS']
        const isBrave = (() => {
            const navigatorInstance = navigator as any
            return !!navigatorInstance.brave
        })()

        const isSafari: boolean =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.includes('Safari') &&
            !uniqueUserAgentSignatures.some(signature => navigator.userAgent.includes(signature)) &&
            !isBrave

        // For the case of IOS devices, only display wallet on Safari
        if (isIOSdevice) {
            return isSafari ? true : false
        } // Else rely on the internal native checks of whether this component displays or not
        return true
    }

    return (
        <>
            {shouldAppleWalletRender() && (
                <div className="apple-wallet-container">
                    <JourneyActionButtonComponent {...buttonProps} />
                </div>
            )}
        </>
    )
}

export default AppleWallet
