import { BookingStepperStepProps, BookingStepperStepStateProps, WorkflowNavigationPropsFromBe } from '../../types'

import { v4 as uuidv4 } from 'uuid'


export function createSteps(props: WorkflowNavigationPropsFromBe, isUnallocated?:boolean): BookingStepperStepProps[] {
    const currentIndex = props.workflow.ticketingWorkflow.currentIndex
    const pagesCount = props.workflow.ticketingWorkflow.pages.length

    const steps =  props.workflow.ticketingWorkflow.pages.map((page, idx) => ({
        name: page.name ?? '@Missing page.name!',
        state: state(
            currentIndex, 
            isUnallocated ? idx - 1 : idx, 
            isUnallocated ? pagesCount - 1 : pagesCount),
        uuid: uuidv4(),
    }))

    return isUnallocated ? steps.filter((p, i) => !!(i !== 0)) : steps
}

function state(currentPageIdx: number, mapIdx: number, mapLength: number): BookingStepperStepStateProps {
    const lastIndex = mapLength - 1
    return {
        complete: mapIdx < currentPageIdx,
        inProgress: currentPageIdx === mapIdx,
        incomplete: currentPageIdx < mapIdx,

        isFirst: mapIdx === 0,
        isLast: mapIdx === lastIndex,
        currentIndex: currentPageIdx,
    }
}
