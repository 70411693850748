import { getAllQueryParameters } from "./getAllQueryParameters";

export function getAttributesFromQuery():string[] {
    const attributeIds:string[] = []
    const queryParamsObject = getAllQueryParameters()
    for (const property in queryParamsObject) {

        if(property.startsWith('attribute')) {
            attributeIds.push(queryParamsObject[property])
        }
      }
      return attributeIds
}