import {
    CustomerDetailsFormFieldComponent,
    CustomerDetailsFormFieldName,
    CustomerDetailsFormHandles,
    CustomerDetailsFormComponent as OccCustomerDetailsFormComponent,
} from '@vista/omnichannel-components-ui'
import React, { forwardRef } from 'react'
import { customerDetailsFormProps, fieldDisplayText } from './props'

import { CustomerDetailsPropsFromBe } from '../../types'
import {
    CustomerDetailsFormData
} from "@vista/omnichannel-components-ui/lib/src/lib/components/customer-details-form/types/data";

interface ICustomerDetailsForm extends CustomerDetailsPropsFromBe {
    onAfterSuccessfulSubmit?: (customerDetails: CustomerDetailsFormData) => Promise<void> | void;
}


export const CustomerDetailsFormComponent = forwardRef<CustomerDetailsFormHandles, ICustomerDetailsForm>(
    ({ onAfterSuccessfulSubmit, ...beProps }, ref) => {
        return (
            <OccCustomerDetailsFormComponent
                ref={ref}
                {...customerDetailsFormProps(beProps)}
                events={{ onAfterSuccessfulSubmit }}
            >
                <CustomerDetailsFormFieldComponent
                    name={CustomerDetailsFormFieldName.GivenName}
                    displayText={fieldDisplayText(CustomerDetailsFormFieldName.GivenName, beProps.displayText)}
                />
                <CustomerDetailsFormFieldComponent
                    name={CustomerDetailsFormFieldName.FamilyName}
                    displayText={fieldDisplayText(CustomerDetailsFormFieldName.FamilyName, beProps.displayText)}
                />
                <CustomerDetailsFormFieldComponent
                    name={CustomerDetailsFormFieldName.Email}
                    displayText={fieldDisplayText(CustomerDetailsFormFieldName.Email, beProps.displayText)}
                />
                <CustomerDetailsFormFieldComponent
                    name={CustomerDetailsFormFieldName.PhoneNumber}
                    displayText={fieldDisplayText(CustomerDetailsFormFieldName.PhoneNumber, beProps.displayText)}
                />
            </OccCustomerDetailsFormComponent>
        )
    }
)
