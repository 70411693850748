import { MemberSubscriptionProps } from '@vista/omnichannel-components-ui'
import { MemberSubscriptionPropsFromBe } from '../../../types'

export function bannerProps(props: MemberSubscriptionPropsFromBe, onRenewClick: () => void): MemberSubscriptionProps {
    const defaultConfig: MemberSubscriptionProps['config'] = {
        expiringSoonThresholdInMonths: 1,
    }
    // @TODO missing
    const defaultDisplayText: MemberSubscriptionProps['displayText'] = {
        recurringSubscriptionTagLabel: 'Recurring',
        nonRecurringSubscriptionTagLabel: 'One-Off Payment',
        onHoldTagLabel: 'On Hold',
        expiringSoonTagLabel: 'Expiring Soon',
        paymentFailedTagLabel: 'Payment Failed',
        cancelledTagLabel: 'Cancelled',
        changeSubscriptionButtonLabel: 'Change Subscription',
        cancelSubscriptionButtonLabel: 'Cancel subscription',
        renewSubscriptionButtonLabel: 'Renew now',
        futureNonRecurringSubscriptionLabel:
            'Valid from ${futureSubscriptionStartDate|d|Do MMMM YYYY} until ${futureSubscriptionEndDate|d|Do MMMM YYYY}',
        nextSubscriptionPaymentDueLabel: [
            'Next subscription payment due ',
            {
                text: '${nextSubscriptionPaymentDueDate|d|Do MMMM YYYY}',
                className: 'v-member-subscription-base__description-date',
            },
        ],
        canRenewOnLabel: [
            'Can renew on ',
            {
                text: '${canRenewOnDate|d|Do MMMM YYYY}',
                className: 'v-member-subscription-base__description-date',
            },
        ],
        lastSubscriptionPaymentFailedLabel: [
            'Last subscription payment failed to charge on ',
            {
                text: '${lastSubscriptionPaymentFailedDate|d|Do MMMM YYYY}',
                className: 'v-member-subscription-base__description-date',
            },
        ],
        activeAgainLabel: [
            'Active again on ',
            {
                text: '${activeAgainDate|d|Do MMMM YYYY}',
                className: 'v-member-subscription-base__description-date',
            },
        ],
        startDateLabel: ['Start Date ', '${date|d|D MMM YYYY}'],
        endDateLabel: ['End Date ', '${date|d|D MMM YYYY}'],
        cancellationModalHeading: 'Cancel your subscription',
        cancellationModalMessage: [
            'Your subscription will be cancelled on ',
            {
                text: '${endDate|d|D MMM YYYY}',
                className: 'v-member-subscription-cancellation-modal__description--end-date',
            },
            ". You can keep using your subscription's benefits until that date.",
            '\n',
            'To continue, confirm your cancellation. This action cannot be undone.',
        ],
        cancellationFromFrozenSubscriptionModalMessage:
            'Your subscription will be cancelled immediately. \n To continue, confirm your cancellation. This action cannot be undone.',
        cancellationModalConfirmButtonLabel: 'Confirm',
        cancellationModalCloseButtonLabel: 'Go back',
        failedToCancelSubscriptionMessage: 'Sorry, something went wrong.',
        successfullyCancelledSubscriptionMessage:
            "We've cancelled your subscription. You can keep using its benefits until: ${endDate|d|D MMM YYYY}.",
        successfullyCancelledFromFrozenSubscriptionMessage: "We've cancelled your subscription.",
    }


    const bannerPropsDisplayText:MemberSubscriptionProps['displayText'] = {
        ...defaultDisplayText,

        recurringSubscriptionTagLabel: props?.displayText?.recurringSubscriptionTagLabel || defaultDisplayText.recurringSubscriptionTagLabel,
        nonRecurringSubscriptionTagLabel: props?.displayText?.nonRecurringSubscriptionTagLabel || defaultDisplayText.nonRecurringSubscriptionTagLabel,
        onHoldTagLabel: props?.displayText?.onHoldTagLabel || defaultDisplayText.onHoldTagLabel,
        expiringSoonTagLabel: props?.displayText?.expiringSoonTagLabel || defaultDisplayText.expiringSoonTagLabel,
        paymentFailedTagLabel: props?.displayText?.paymentFailedTagLabel || defaultDisplayText.paymentFailedTagLabel,
        nextSubscriptionPaymentDueLabel: props?.displayText?.nextSubscriptionPaymentDueLabel || defaultDisplayText.nextSubscriptionPaymentDueLabel,
        canRenewOnLabel: props?.displayText?.canRenewOnLabel || defaultDisplayText.canRenewOnLabel,
        changeSubscriptionButtonLabel: props?.displayText?.changeSubscriptionButtonLabel || defaultDisplayText.changeSubscriptionButtonLabel,
        renewSubscriptionButtonLabel: props?.displayText?.renewSubscriptionButtonLabel || defaultDisplayText.renewSubscriptionButtonLabel,
    }

    return {
        displayText: {
            ...bannerPropsDisplayText
        },
        events: {
            onChangeSubscriptionButtonClicked: onRenewClick,
            onRenewButtonClicked: onRenewClick,
        },
    }
}
