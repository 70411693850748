export function getAllQueryParameters():{[key: string]: string} {
    const url = window.location.href
    const allParams = url.split('?')[1]
    if(!allParams) return
    const queryParams:string[] = [...allParams.split('&')]
    if(!queryParams) return
    const queryParamObject:{[key: string]: string} = {}
    queryParams.forEach(param => {
        const paramSplit = param.split("=")
        queryParamObject[paramSplit[0]] = paramSplit[1]
    })
    return queryParamObject
}