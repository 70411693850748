import React from 'react'
import { CategoriesProps, CategoryProps } from './../../types'

const categoryRename = (category:string):string=>{
    const categoryObj = {
        Film:'Films',
        Event:'Events',
        Cinema:'Cinemas',
        JournalArticle:'Journal'
    }

    return categoryObj[category] ? categoryObj[category] : category;
}


export const Categories: React.FC<CategoriesProps> = props => (
    <div className={props.className}>
        <div className={`${props.classBase}__category`}>
            {!!props.results.length && /* move it to map, change interface on BE */(
                <h4 className={`${props.classBase}__category-name`}>{categoryRename(props.categoryName)}</h4>
            )}
            {props.results?.map((el, idx) => (
                <Category {...el} key={el.id || idx} classBase={props.classBase} />
            ))}
        </div>
    </div>
)


export const Category: React.FC<CategoryProps> = props => (
    <div className={`${props.classBase}__element--white`}>
        <h3 className={`${props.classBase}__title`}>
            <a href={props.url} title={props.title}>{props.title}</a>
        </h3>
    </div>
)
