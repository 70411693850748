import React, { useContext } from 'react'
import { rewardProps, showOnlyTwoRewards } from './props'

import { MemberAccContext } from '../../context'
import { MemberRewardList } from '@vista/omnichannel-components-ui/lib/src/lib/components/member-reward-list/member-reward-list'
import { Title } from '..'

const viewShowtimesRedirect = ctx => {
    if (ctx?.MemberRewardViewShowtimes?.displayText?.viewShowtimesLink?.href)
        window.location.assign(ctx.MemberRewardViewShowtimes.displayText.viewShowtimesLink.href)
}

export const MemberAccRewardComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    const pageTitle = ctx.MemberRewardViewShowtimes.displayText.pageTitle

    return (
        <>
            <Title>{pageTitle}</Title>
            <MemberRewardList
                {...rewardProps(ctx.MemberRewardList)}
                events={{
                    onActionButtonClicked: () => viewShowtimesRedirect(ctx),
                    onModalActionButtonClicked: () => viewShowtimesRedirect(ctx),
                }}
            />
        </>
    )
}
export const MemberAccRewardOverviewComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    const memberRewardsProps = showOnlyTwoRewards(rewardProps(ctx.MemberRewardList));
    return (
        <>
            <Title>Rewards</Title>
            <MemberRewardList
                {...showOnlyTwoRewards(rewardProps(ctx.MemberRewardList))}
                events={{
                    onModalActionButtonClicked: () => viewShowtimesRedirect(ctx),
                }}
            />
        </>
    )
}
