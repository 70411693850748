import React, { useState } from 'react'
import { OrderExpiryCountdownComponent, Modal } from '@vista/omnichannel-components-ui'
import { OrderExpiryCountdownPropsFromBe } from '../../types'
import { orderExpiryProps } from './props'
import { goHome } from '../workflowNavigation'
import { clearOrder } from '@vista/omnichannel-components-domain'

export const OrderExpiryComponent: React.FC<OrderExpiryCountdownPropsFromBe> = props => {
    const [isModalVisible, setShowModal] = useState<boolean>(false)
    const showModal = () => setShowModal(true)

    const handleRestartOrderClick = () => {
        clearOrder()
        goHome()
    }

    return (
        <div>
            <OrderExpiryCountdownComponent {...orderExpiryProps(props, showModal)} />
            <Modal className="v-modal__order-expired" show={isModalVisible} onClose={handleRestartOrderClick} heading="Order expired">
                <p>Timeout.</p>
                <button className='btn--pink center' onClick={handleRestartOrderClick}>Restart order</button>
            </Modal>
        </div>
    )
}
