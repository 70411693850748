import { BookNowPropsFromBe, BookNowPropsType } from './../../types'
import { Modal, QuickNavigatorComponent, QuickNavigatorHandles } from '@vista/omnichannel-components-ui'
import React, { useEffect, useRef, useState } from 'react'
import { resetBookingStore, scrollEffects } from '../../utils'

import { CreateQuickNavigatorProps } from './props'

export const BookNowComponent: React.FC<BookNowPropsFromBe> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onClickHandler = () => setIsOpen(!isOpen)

    const hideQuickNavigator = isOpen ? 'hide-navigator' : ''

    scrollEffects()

    const isVisible:boolean = !window.location.pathname.includes("gift-shop-customer-details") 
                                && !window.location.pathname.includes("gift-shop-payment")
                                && !window.location.pathname.includes("gift-card-confirmation")
                                && !(window.location.pathname.includes("gift-shop") && window.location.pathname.includes("payment-page"))
                                && !(document.getElementById("FilmDetailsWrap"))

    if(!isVisible) return null
    
    return (

        <>
            <div className={`book-now-container ${hideQuickNavigator}`}>
                <button onClick={onClickHandler} className="main-nav__extra__book-now">
                    {props.displayText.headingLabel}
                </button>
            </div>
            <Modal
                show={isOpen}
                onClose={onClickHandler}
                className="modal__quick-book"
                heading={props.displayText.headingLabel}
                closeButtonIconName="cross"
            >
                <QuickNavigatorWrapWithRef {...props} />
            </Modal>
        </>
    )
}

/** This component is used to wrap React.ref and QuickNavigatorComponent.
 *  This way we can useRef on same component level as
 *  QuickNavigatorComponent is, and don't need to worry about it's scope.
 */
export const QuickNavigatorWrapWithRef: React.FC<BookNowPropsFromBe> = props => {
    const quickNavigatorRef = useRef<QuickNavigatorHandles>()
    const [propsWithRef, setPropsWithRef] = useState<BookNowPropsType>(
        CreateQuickNavigatorProps(props, quickNavigatorRef.current)
    )
    useEffect(() => {
        //resetBookingStore()
        setPropsWithRef(CreateQuickNavigatorProps(props, quickNavigatorRef.current))
    }, [quickNavigatorRef])
    return <QuickNavigatorComponent {...propsWithRef} ref={quickNavigatorRef} />
}

export default BookNowComponent