import { ThreeDGlassesPickerDetailsPropsFromBe } from '../../types'
import { ThreeDGlassesPickerProps } from '@vista/omnichannel-components-ui'
import {quantityLimitAdapter} from '../../utils'

export function threeDGlassesPickerProps(props: ThreeDGlassesPickerDetailsPropsFromBe): ThreeDGlassesPickerProps {
    const returnProps =  {
        ...props,
        displayText: displayText(props),
        config: config(props),
        assets: assets(),
        cdnOptions: cdnOptions(),
        itemHopk: '[item hopk]',
        events: {},
    }

    /*@ts-ignore*/
    delete returnProps.workflow

    return returnProps
}

function config(props: ThreeDGlassesPickerDetailsPropsFromBe): ThreeDGlassesPickerProps['config'] {
    return {
        ...props.config,
        quantityLimit: quantityLimitAdapter(props.config.quantityLimit),
    }
}

function displayText(props: ThreeDGlassesPickerDetailsPropsFromBe): ThreeDGlassesPickerProps['displayText'] {
    return {
        ...props.displayText,
    }
}

function assets(): ThreeDGlassesPickerProps['assets'] {
    return {
        failedToLoadDataIconName: 'box-with-cross-eyes-and-warning-symbol',
        addItemButtonIconName: 'shopping-cart',
        closeModalButtonIconName: 'cross',
    }
}

function cdnOptions(): ThreeDGlassesPickerProps['cdnOptions'] {
    return {
        itemThumbnail: { width: 520 },
        itemImage: { width: 640 },
    }
}
