import { loadMember } from '@vista/omnichannel-components-domain';
import { MemberAccContext } from '../../../context';
import { useContext, useEffect, useState } from 'react';


//hook to get the background color for the current and future subscription infrom the cms
export const useSubscriptionBackground = () => {
    const ctx = useContext(MemberAccContext);
    const [currentBackground, setCurrentBackground] = useState('f8a4d8')
    const [futureBackground, setFutureBackground] = useState('f8a4d8')

    useEffect(() => {
        (async () => {
            const member = await loadMember()
            
            if (member?.subscriptions?.current) {
                const bg = getBackground(member.subscriptions.current.subscriptionId);
                setCurrentBackground(bg)
            }

            if (member?.subscriptions?.future) {
                const bg = getBackground(member.subscriptions.future.subscriptionId);
                setFutureBackground(bg)
            }
        })()
    }, [])

    const getBackground = (subscriptionId: number) => {
        const sId = subscriptionId.toString();

        const subscriptionDetails = ctx.MemberSubscriptionsDetails?.memberSubscriptionDetails.find(s =>
            s.vistaSubscriptionIds.includes(sId)
        )

        return `#${(subscriptionDetails?.headerColor || 'f8a4d8')}`
    }
    

    return { currentBackground, futureBackground }
}


