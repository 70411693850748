import { CarouselHeaderPropsType, PromoEventType, PromotedEventsPropsType } from '../../../../types'

import { Header } from '../Header'
import { Slide } from './Slide'
import { SwiperReactFacade } from '../../'
import { fourColSettings } from '../..'
import { md5 } from '../../../../utils'

export const PromotedEventsComponent: React.FC<PromotedEventsPropsType> = props => {
    const { displayText } = props
    const  items = displayText.items.map( item => ({...item, id: uuid(item)}))
    const options = fourColSettings(displayText.items.length)
    const hideArrows = items.length <= 4
    

    const headerProps: CarouselHeaderPropsType = {
        title: displayText.title,
        ctaLink: displayText.viewAllLink
    }

    const hasStyle = !!props?.displayText?.backgroundColor?.color

    const styleObject:React.CSSProperties = hasStyle ? {
        backgroundColor: `#${props.displayText?.backgroundColor.color}`
    } : {}

    return (
        <>
        <Header {...headerProps} />
            <SwiperReactFacade options={options} hideArrows={hideArrows} className="event-carousel promo-event-carousel width--normal padding--add">
                {items.map(eventItem => <Slide {...eventItem} key={eventItem.id} styleObject={styleObject} />)}
            </SwiperReactFacade>
        </>
    )
}

function uuid(item: PromoEventType) {
    return md5(
        item.ctaLink?.url +
            item.image?.imageCropDetails?.src +
            item.startDate +
            item.location +
            item.headline +
            item.subHeadline +
            item.ctaLink?.name
    )
}

export default PromotedEventsComponent