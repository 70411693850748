import {
    TicketPickerAssets,
    TicketPickerConfig,
    TicketPickerDisplayText,
    TicketPickerProps,
    TicketPickerSubcomponents,
} from '@vista/omnichannel-components-ui'

import { TicketPickerPropsFromBe } from '../../types'
import { defaultTicketPickerSubcomponents } from '@vista/omnichannel-components-ui/lib/src/lib/components/showtime/ticket-picker/types/subcomponents'

export function ticketPickerProps(props: TicketPickerPropsFromBe): TicketPickerProps {
    const returnProps =  {
        ...props,
        displayText: displayText(props),
        config: config(props),
        assets: defaultAssets(),
        subcomponents: defaultSubcomponents(),
    }

    delete returnProps.workflow

    return returnProps
}


function defaultSubcomponents(): TicketPickerSubcomponents {
    return defaultTicketPickerSubcomponents
}

function defaultAssets(): TicketPickerAssets {
    return {
        failedToLoadDataIconName: 'ticket-with-cross-eyes',
    }
}

function config(props: TicketPickerPropsFromBe): TicketPickerConfig {
    return {
        ...props.config,
    }
}

function displayText(props: TicketPickerPropsFromBe): TicketPickerDisplayText {
    const defaultDisplayText = {
        counterLabel: 'Tickets have been chosen',
        tableColumnItemHeaderLabel: 'Item',
        tableColumnPriceHeaderLabel: 'Price',
        tableColumnQuantityHeaderLabel: 'Quantity',
        tableColumnSubtotalHeaderLabel: 'Subtotal',
        errorTooManyTicketsSelectedMessage:
            'You have too many tickets. Remove tickets or add more seats to your order.',
        errorTooFewTicketsSelectedMessage: 'Your selected tickets count is less than the seats count.',
        errorTicketsDoNotMatchAreaCategoriesMessage: 'Your selected tickets do not match your selected seats.',
        errorNoTicketsSelectedMessage: 'You must select at least one ticket.',
        failedToLoadDataTitle: 'Sorry, something went wrong.',
        failedToLoadDataMessage: 'Refresh your browser to try loading ticket options again.',
        includedBookingFeeFormat: 'includes booking fee of ${bookingFee}',
        excludedBookingFeeFormat: 'plus booking fee of ${bookingFee}',
        errorFailedToSaveTitle: 'Failed to save ticket selection!',
        errorFailedToSaveMessage: 'Refresh your browser to try saving again.',
        errorInsufficientSeatsAvailableTitle: 'Not enough seats left!',
        errorInsufficientSeatsAvailableMessage:
            "Sorry, it looks like we don't have enough seats left in this showtime. Please adjust your order or try another showtime.",
        ticketSingleUseRedemptionDetailsFormat: '${formattedCodeDetails}',
        ticketMultiUseRedemptionDetailsFormat:
            '${formattedCodeDetails} (${remainingRedemptions}/${maximumRedemptions} remaining)',
        ticketUnlimitedUseRedemptionDetailsFormat: '${formattedCodeDetails}',
        ticketRedemptionCodeDetailsFormat: '${maskedCode}',
        ticketRedemptionCodeDetailsNamedFormat: '${formattedMemberName}',
    }

    return {
        ...defaultDisplayText,
        ...props.displayText,
    }
}
