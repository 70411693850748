export function scrollEffects(): void {
    const scrollDown = 'scroll-down'
    const initialOffset = window.pageYOffset
    let oldValue = initialOffset
    let newValue = initialOffset

    const body = document.querySelector('body')

    if (!body.classList.contains('scroll-effect-added')) {
        window.addEventListener('scroll', () => {
            document.querySelector('body')
            newValue = window.pageYOffset
            if (body) {
                body.classList.add('scroll-effect-added')
                if (oldValue < newValue) {
                    body.classList.add(scrollDown)
                } else {
                    body.classList.remove(scrollDown)
                }
            }
            oldValue = newValue
        })
    }
}
