export function getPrivateInquiryCinemas() {
    const url = new URL('/api/privateinquiry/getcinemas/', window.location.origin)

    const callApi = async () => {
        const res = await fetch(url.href)
        const json = await res.json()

        return json
    }

    return callApi()
}