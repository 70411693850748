import { MemberDetailsFormPropsFromBe, MemberPhotoUploaderPropsFromBe } from '../../../types'
import {
    MemberFormDateOfBirthFieldProps,
    MemberFormInputFieldProps,
    MemberPhotoUploaderProps,
    UpdateMemberDetailsFormFieldName,
} from '@vista/omnichannel-components-ui'
import {
    defaultMemberFormAddressFieldDisplayText,
    defaultMemberFormCityFieldDisplayText,
    defaultMemberFormDateOfBirthFieldDisplayText,
    defaultMemberFormFamilyNameFieldDisplayText,
    defaultMemberFormGivenNameFieldDisplayText,
    defaultMemberFormHomeNumberFieldDisplayText,
    defaultMemberFormMobileNumberFieldDisplayText,
    defaultMemberFormPostCodeFieldDisplayText,
    defaultMemberFormStateFieldDisplayText,
} from '@vista/omnichannel-components-ui/lib/src/lib/subcomponents/member-form-field/types/display-text'

import { defaultMemberFormDateOfBirthFieldConfig } from '@vista/omnichannel-components-ui/lib/src/lib/subcomponents/member-form-field/types/config/date-of-birth-field'
import { defaultMemberPhotoUploaderProps } from '@vista/omnichannel-components-ui/lib/src/lib/components/member/photo-uploader/types/props'

export function memberPhotoUploaderProps(props: MemberPhotoUploaderPropsFromBe): MemberPhotoUploaderProps {
    const returnProps = {
        ...defaultMemberPhotoUploaderProps,
        ...props,
    }

    /*@ts-ignore*/
    delete returnProps.assets.notificationErrorIconName

    returnProps.assets.updatePhotoButtonIconName = undefined

    return returnProps
}

type Fields =
    | 'givenName'
    | 'familyName'
    | 'mobileNumber'
    | 'homeNumber'
    | 'address'
    | 'suburb'
    | 'city'
    | 'state'
    | 'postCode'
    | 'dateOfBirth'

type IUpdateMemberFieldsProps = {
    [key in Fields]: MemberFormInputFieldProps | MemberFormDateOfBirthFieldProps
}

export function updateMemberFieldsProps(props: MemberDetailsFormPropsFromBe): IUpdateMemberFieldsProps {
    const txt = props.displayText
    return {
        givenName: {
            name: UpdateMemberDetailsFormFieldName.GivenName,
            displayText: {
                label: txt.fieldGivenNameLabel || defaultMemberFormGivenNameFieldDisplayText.label,
                requiredMessage:
                    txt.errorGivenNameRequiredMessage || defaultMemberFormGivenNameFieldDisplayText.requiredMessage,
            },
        },
        familyName: {
            name: UpdateMemberDetailsFormFieldName.FamilyName,
            displayText: {
                label: txt.fieldFamilyNameLabel || defaultMemberFormFamilyNameFieldDisplayText.label,
                requiredMessage:
                    txt.errorFamilyNameRequiredMessage || defaultMemberFormFamilyNameFieldDisplayText.requiredMessage,
            },
        },
        mobileNumber: {
            name: UpdateMemberDetailsFormFieldName.MobileNumber,
            displayText: {
                label: txt.fieldMobileNumberLabel || defaultMemberFormMobileNumberFieldDisplayText.label,
                requiredMessage:
                    txt.errorMobileNumberRequiredMessage ||
                    defaultMemberFormMobileNumberFieldDisplayText.requiredMessage,
            },
        },
        homeNumber: {
            name: UpdateMemberDetailsFormFieldName.HomeNumber,
            displayText: {
                label: txt.fieldHomeNumberLabel || defaultMemberFormHomeNumberFieldDisplayText.label,
                requiredMessage:
                    txt.errorHomeNumberRequiredMessage || defaultMemberFormHomeNumberFieldDisplayText.requiredMessage,
            },
        },
        address: {
            name: UpdateMemberDetailsFormFieldName.Address,
            displayText: {
                label: txt.fieldAddressLabel || defaultMemberFormAddressFieldDisplayText.label,
                requiredMessage:
                    txt.errorAddressRequiredMessage || defaultMemberFormAddressFieldDisplayText.requiredMessage,
            },
        },
        suburb: {
            name: UpdateMemberDetailsFormFieldName.Suburb,
            displayText: {
                label: txt.fieldSuburbLabel || defaultMemberFormAddressFieldDisplayText.label,
                requiredMessage:
                    txt.errorSuburbRequiredMessage || defaultMemberFormAddressFieldDisplayText.requiredMessage,
            },
        },
        city: {
            name: UpdateMemberDetailsFormFieldName.City,
            displayText: {
                label: txt.fieldCityLabel || defaultMemberFormCityFieldDisplayText.label,
                requiredMessage: txt.errorCityRequiredMessage || defaultMemberFormCityFieldDisplayText.requiredMessage,
            },
        },
        state: {
            name: UpdateMemberDetailsFormFieldName.State,
            displayText: {
                label: txt.fieldStateLabel || defaultMemberFormStateFieldDisplayText.label,
                requiredMessage:
                    txt.errorStateRequiredMessage || defaultMemberFormStateFieldDisplayText.requiredMessage,
            },
        },
        postCode: {
            name: UpdateMemberDetailsFormFieldName.PostCode,
            displayText: {
                label: txt.fieldPostCodeLabel || defaultMemberFormPostCodeFieldDisplayText.label,
                requiredMessage:
                    txt.errorPostCodeRequiredMessage || defaultMemberFormPostCodeFieldDisplayText.requiredMessage,
            },
        },
        dateOfBirth: {
            name: UpdateMemberDetailsFormFieldName.DateOfBirth,
            config: defaultMemberFormDateOfBirthFieldConfig, //{mask:"dd/mm/yyyy"}, //defaultMemberFormDateOfBirthFieldConfig,
            displayText: {
                label: txt.fieldDateOfBirthLabel || defaultMemberFormDateOfBirthFieldDisplayText.label,
                requiredMessage:
                    txt.errorDateOfBirthRequiredMessage || defaultMemberFormDateOfBirthFieldDisplayText.requiredMessage,
            },
        },
    }
}
