import {
    isMemberSignedIn,
    getMemberId,
    getMemberClubMembership,
    getClubLevelById,
    loadMemberIfSignedIn,
} from '@vista/omnichannel-components-domain'
import { debug } from './log'

export const AddToDataLayer = async (obj: any): Promise<void> => {
    const w = window as any
    w.dataLayer = w.dataLayer || []

    if (obj && typeof obj === 'object') {
        obj['membership_tier'] = await getMembershipTier()
    }

    debug('Adding to dataLayer:', obj)
    w.dataLayer.push(obj)
}

export const AddLoggedMemberToDataLayer = (): void => {
    let memberId = null
    if (isMemberSignedIn()) {
        memberId = getMemberId()
        if (memberId) {
            AddToDataLayer({
                'customer-id': memberId,
            })
        }
    }
}

export const getMembershipTier = async (): Promise<string> => {
    try {
        const member = await loadMemberIfSignedIn()
        const clubLevelId = member?.clubMembership?.clubLevelId
        if (clubLevelId) {
            const clubLevel = getClubLevelById(clubLevelId)
            if (clubLevel) return clubLevel.name.text
        }
    } catch {}

    return 'None'
}
