import React from "react"


export interface SubheadingProps {
    heading: string | React.ReactNode
    baseClass?:string
    isLeftAligned?:boolean
}

export const Subheading = (props:SubheadingProps) => {
    const {baseClass, heading, isLeftAligned} = props
    const b = baseClass && baseClass.length ? baseClass + "__" : ''
    const alignmentClass = isLeftAligned ? "left-aligned" : ""

    return (
        <h4 className={`${b}subheading form-subheading ${alignmentClass}`}>{heading}</h4>
    )
}