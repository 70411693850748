import { OrderTotalsConfig, OrderTotalsProps } from '@vista/omnichannel-components-ui'

import { NestedPartial } from '@vista/omnichannel-components-domain'
import { OrderTotalsPropsFromBe } from '../../types'

export function orderTotalsProps(props: OrderTotalsPropsFromBe): NestedPartial<OrderTotalsProps> {
    return {
        // displayText: {
        //     ...props.displayText
        // }, displayText now comes from global config
        config: config(),
    }
}

export function config(): OrderTotalsConfig {
    return {
        showBookingFee: true,
        showEstimatedTaxLabel: true,
    }
}
