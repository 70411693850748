import { FilterType, getFilterByIdAndType } from "@vista/omnichannel-components-domain"

export function createAttributeQueryString(filterId:string):string {
    const filter = getFilterByIdAndType(filterId, FilterType.Film)
    const selectedAttributes = []
    filter.sections.attribute.options.forEach(option => {
        if(option.selected) selectedAttributes.push(option.value)
    })

    let queryParamString:string = ""

    if(selectedAttributes.length) {
        queryParamString += "?"

        selectedAttributes.forEach((a, i) => {
            let parameter = ""
            const s = `attribute${i + 1}=${a}`
            parameter += i !== 0 ? `&${s}` : s 
            queryParamString += parameter
        } 
    )}

    return queryParamString
}