import { Film } from '@vista-digital/ocapi-types/v1'
import { FilmThumbnailMediaType } from '@vista/omnichannel-components-ui'
import {MemberFilmWatchlistProps} from '@vista/omnichannel-components-ui'
import { MovioTracker } from '../../../utils'
import { UrlFactory } from '../../../utils'
import { WatchlistPropsFromBe } from '../../../types'

export function watchlistProps(props: WatchlistPropsFromBe): MemberFilmWatchlistProps {
    const urlFactory = new UrlFactory(props.workflow)
    const movioTracker = new MovioTracker()

    function redirectTofFilmPage(film: Film): void {
        const filmPagePath = urlFactory.getFilmDetailsUrl(film)
        window.location.assign(filmPagePath)
    }

    function redirectTofFilmsPage(): void {
        const redirectUrl = `${window.location.origin}/films`
        window.location.assign(redirectUrl)
    }

    const returnProps =  {
        ...props,
        config: { ...props.config, playTrailerButton: {
            show: props.config.playTrailerButton.show,
            showControls: true,
            autoPlay: true,
            muted: true,
            loop: true,
        }, 
        filmMediaType: FilmThumbnailMediaType[props.config.filmThumbnailMediaType] },
        events: {
            onFilmClicked: (film: Film) => redirectTofFilmPage(film),
            onEmptyListButtonClicked: () => {
                redirectTofFilmsPage()
            },
            onPlayTrailerButtonClicked: (film) => {
                movioTracker.movieTrailer(film.id)
            },
            onFilmRemoved: (film) => {
                movioTracker.movieWatchList(film.id, true)
            }
        },
        assets: {
            emptyWatchlistIconName: 'film-strip',
            failedToLoadDataIconName: 'curzon-warning',
            removeFilmButtonIconName: 'cross-circle',
            failedToLoadTrailerIconName: 'curzon-warning',
            playTrailerButtonIconName: 'play-circle',
        },
        cdnOptions: {
            filmThumbnailImage: { width: 380 },
            censorRatingIcon: { height: 60 },
        },
    }

    delete returnProps.config.filmThumbnailMediaType
    delete returnProps.workflow

    return returnProps
}
