import { useState } from 'react'
import cls from 'classnames'

export interface GlobalNotificationBannerProps {
    displayText: {
        text: string
    }
}

export const GlobalNotificationBannerComponent: React.FC<GlobalNotificationBannerProps> = props => {
    const storageItem = sessionStorage.getItem('HideGlobalNotification')
    const [bannerDisplayToggle, setBannerDisplayToggle] = useState(false)

    const globalNotificationCls = cls({
        hide: storageItem,
        'global-notification-banner': !storageItem,
    })

    
    const handleCloseBanner = () => {
        if (!sessionStorage.getItem('HideGlobalNotification')) {
            sessionStorage.setItem('HideGlobalNotification', 'true')
            setBannerDisplayToggle(!bannerDisplayToggle)
        }
    }

    return (
        <div className={globalNotificationCls} style={{ display: props.displayText.text ?? 'none' }}>
            <div
                className="global-notification-banner__content"
                dangerouslySetInnerHTML={{ __html: props.displayText.text }}
            ></div>

            <button className="global-notification-banner__button" onClick={handleCloseBanner}>
                <svg className="global-notification-banner__icon">
                    <use xlinkHref={'#c-icon_cross-white'}></use>
                </svg>
            </button>
        </div>
    )
}

export default GlobalNotificationBannerComponent