import { CdnFilm } from '@vista-digital/cdn-client'
import { RelatedFilmItem } from '../../../../types'
import { SwiperReactFacadeSlide } from '../..'
import { UrlFactory } from '../../../../utils/urlFactory'
import { cdnClient } from '@vista/omnichannel-components-domain'
import React from 'react'

export const Slide: React.FC<RelatedFilmItem> = props => {
    const urlFactory = new UrlFactory(props.workflow)

    function getFilmStill(film: RelatedFilmItem) {
        const cdnFilm: CdnFilm = { id: film.filmId, externalIds: {} }
        console.debug('cdnClient.instance.film(cdnFilm).still()', cdnClient.instance.film(cdnFilm).still())
        return cdnClient.instance.film(cdnFilm).still()
    }

    const filmStill = getFilmStill(props)

    if (!props.title || !props.filmId) return null

    return (
        <SwiperReactFacadeSlide>
            <a className="film-slide" href={urlFactory.getFilmDetailsUrlWithoutFilm(props.title, props.filmId)}>
                {filmStill && <img className="film-slide__image" src={filmStill} alt={props.title} />}

                {!filmStill && <img className="event-slide__image" alt="image not found" />}

                <div className="film-slide__details">
                    <h3>{props.title}</h3>
                </div>
            </a>
        </SwiperReactFacadeSlide>
    )
}
