import React, { useContext } from 'react'

import { MemberAccContext } from '../../context'
import { MemberPaymentCardWalletComponent } from '@vista/omnichannel-components-ui';
import { cardWalletProps } from './props'

export const MemberAccCardWalletComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)

    return (
        <div className='member-card-wallet'>
            <h2 className="member-card-wallet__header">Card Wallet</h2>
            <MemberPaymentCardWalletComponent {...cardWalletProps(ctx.CardWallet)} />
        </div>
    )
}
