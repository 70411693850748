import { HeadingLevel, Modal } from '@vista/omnichannel-components-ui'
import { MembershipSalesColumn, MembershipSalesThreeColumnsProps } from '../../types/membershipSalesThreeColumns'
import React, { useState } from 'react'

import { UrlFactory } from '../../utils'

export const MembershipSalesThreeColumnsComponent: React.FC<MembershipSalesThreeColumnsProps> = props => {
    const urlFactory = new UrlFactory(props.workflow)

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [modalProps, setModalProps] = useState<MembershipSalesColumn>()

    const openHandler = (item: MembershipSalesColumn) => {
        setModalProps(item)
        setIsOpen(true)
    }

    const closeHandler = () => {
        setIsOpen(false)
    }

    const joinUrl = (packageName: string, index: number) => {
        if(props.isRenewalPage){
            return urlFactory.getMembershipSubscriptionWorkflowUrl(
                props.workflow.membershipSubscriptionWorkflow.currentIndex + 2,
                packageName
            )
        }

        if (index == 0) {
            return urlFactory.getMembershipSubscriptionWorkflowUrl(
                props.workflow.membershipSubscriptionWorkflow.currentIndex + 2 // Skip package selection
            )
        }

        return urlFactory.getMembershipSubscriptionWorkflowUrl(
            props.workflow.membershipSubscriptionWorkflow.currentIndex + 1,
            packageName
        )
    }

    return (
        <div className="membership-sales-three-columns">
            {props.membershipSalesColumns != null &&
                props.membershipSalesColumns.map((item, index) => (
                    (!props.isRenewalPage || index>0) && 
                    <div key={item.titleText} className={'one-column' + (index == 0 ? ' first' : '')}>
                        <h2>{item.titleText}</h2>
                        <div className="text">{item.description}</div>

                        <div className="list-container">
                            {item.imageTextList != null &&
                                item.imageTextList.map((listItem, index) => (
                                    <div key={index} className="list-item">
                                        <img src={listItem.imageUrl} />
                                        <span>{listItem.text}</span>
                                    </div>
                                ))}
                        </div>
                        <div className="spacing"></div>
                        <div className="buttons-container">
                            <div className="price-container">
                                {item.isMonthly && <span className="monthly-text">{props.fromText}</span>}
                                <span className="price">{item.price}</span>
                                {item.isMonthly && <span className="monthly-text">{props.monthlyText}</span>}
                            </div>

                            <a className="button" href={joinUrl(item.titleText, index)}>
                                {item.buttonLabel}
                            </a>
                            <br />
                            <a className="link" onClick={() => openHandler(item)}>
                                {item.seeAllBenefitsLabel}
                            </a>
                        </div>
                    </div>
                ))}

            <Modal
                show={isOpen}
                onClose={closeHandler}
                className="modal__art-filter"
                closeButtonIconName="c-icon_cross-black"
                heading={modalProps?.seeAllBenefitsModalHeader}
                headingLevel={HeadingLevel.H1}
            >
                <div dangerouslySetInnerHTML={{__html:modalProps?.seeAllBenefitsModalBody}}></div>
            </Modal>
        </div>
    )
}
