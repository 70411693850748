import { Dispatch, SetStateAction, ChangeEvent } from 'react'
import { WorkflowComponent } from './propsFromBe'

export interface ArticleListPropsFromBe extends WorkflowComponent {
    displayText: DisplayText
    promoTextLinkImgItems: PromoTextLinkImg[]
    articleItems: IArticle[]
    filterItems: ArticleFilterFromBe[]
    tabTags: ArticleTabTagsFromBe[]
    authorNodeId: string
}

export interface IArticlesProps {
    articles: IArticle[]
    promo: JSX.Element[]
}

export interface IArticle {
    articleDate: string
    articleTitle: string
    articleUrl: string
    authorName: string
    imageUrl: Img
    introductionText: string
    tagName: string
    typeName: string
}

interface DisplayText {
    title: string
    loadMoreBtn: string
    filterBtn: string
    noResults: string

    foundResults: string
    clearAllBtn: string
    cancelBtn: string
    applyBtn: string
}

export interface PromoTextLinkImg {
    image: Img
    title: string
    text: string
    link: Link
    backgroundColour: string
}

interface Img {
    mobile: string
    tablet: string
    desktop: string
    altText?: string
}

interface Link {
    url?: string
    target?: string
    name?: string
}

export interface PromoAdapterProps {
    idx: number
    show: boolean
}

export interface LoadMoreProps {
    loadMoreLabel: string
    noResultsLabel: string
    onClick: VoidFunction
    moreToLoad: boolean
}

export interface IloadMore {
    url: string
    setMoreToLoad: Dispatch<SetStateAction<boolean>>
    setPreloadedArticles: Dispatch<SetStateAction<IArticle[]>>
    setDisplayedArticles: Dispatch<SetStateAction<IArticle[]>>
    preloadedArticles: IArticle[]
}

export interface IApplyTag {
    tagId: string
    authorId: string
    setMoreToLoad: Dispatch<SetStateAction<boolean>>
    setPreloadedArticles: Dispatch<SetStateAction<IArticle[]>>
    setDisplayedArticles: Dispatch<SetStateAction<IArticle[]>>
    loadedArticles: IArticle[]
}

export interface ArticleFilterProps {
    filters: ArticleFilterFromBe[]
    displayText: DisplayText
    onChangeHandler: (
        filterState: FilterState,
        e?: ChangeEvent<HTMLInputElement>,
        filterFromBe?: ArticleFilterFromBe
    ) => void
    filter: FilterState
    setFilter: Dispatch<SetStateAction<FilterState>>
    filtersCount: number
    articlesCount: number
    setFiltersCount: Dispatch<SetStateAction<number>>
}

export enum ArticleFilterIds {
    authorId = 'authorId',
    tagIds = 'tagIds',
    typeId = 'typeId',
}

export interface ArticleFilterFromBe {
    filterName: string
    filterId: ArticleFilterIds
    filterValues: FilterValues[]
}

interface FilterValues {
    nodeId: string
    name: string
}

type FilterId = 'authorId' | 'tagIds' | 'typeId'

export type FilterState = {
    [key in FilterId]?: string[]
}

export type ArticleTabTagsFromBe = {
    id: number
    name: string
}
