import React, { forwardRef } from 'react'
import { OrderItemPickerPropsFromBe } from './../../types'
import {
    SiteItemPickerComponent as OccSiteItemPickerComponent,
    SiteItemPickerHandles,
} from '@vista/omnichannel-components-ui'
import { siteItemPickerProps } from '.'

export const SiteItemPickerComponent = forwardRef<SiteItemPickerHandles, OrderItemPickerPropsFromBe>((props, ref) => {
    const currentIndex = props.workflow.ticketingWorkflow.currentIndex
    const pageHeading = props.workflow.ticketingWorkflow.pages[currentIndex].name

    return (
        <div className="width--normal padding--add">
            <h1 className="page-heading">{pageHeading}</h1>
            <OccSiteItemPickerComponent ref={ref} {...siteItemPickerProps(props)} />
        </div>
    )
})
