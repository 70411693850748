import { GiftShopItemPickerProps, TabsDisplayType } from '@vista/omnichannel-components-ui'

import { GiftShopItemPickerPropsFromBe } from './../../types'

export function giftShopItemPickerProps(props: GiftShopItemPickerPropsFromBe): GiftShopItemPickerProps {
    const giftShopItemPickerProps: GiftShopItemPickerProps = {
        displayText: {
            ...props.displayText,
        },
        config: {
            ...props.config,
            itemButton: { ...props.config.itemButton, showDescription: true },
            tabs: {
                displayType: TabsDisplayType.Primary
            }
        },
        events: {},
        assets: {
            failedToLoadDataIconName: 'curzon-warning',
            noItemsIconName: 'curzon-fnb',
            addItemButtonIconName: 'shopping-cart',
            closeModalButtonIconName: 'cross',
        },
        cdnOptions: {
            itemThumbnail: { width: 520 },
            itemImage: { width: 640 },
            itemOptionImage: { width: 120 },
        },
    }

    return giftShopItemPickerProps
}
