import { OrderExpiryCountdownProps } from '@vista/omnichannel-components-ui'
import { OrderExpiryCountdownPropsFromBe } from '../../types'

export function orderExpiryProps(
    props: OrderExpiryCountdownPropsFromBe,
    callbackOnOrderExpired: () => void
): OrderExpiryCountdownProps {
    const returnProps =  {
        ...props,
        displayText: props.displayText,
        events: {
            onOrderExpired: callbackOnOrderExpired,
        },
        assets: {
            countdownIconName: 'clock-solid',
        },
    }

    /*@ts-ignore*/
    delete returnProps.workflow

    return returnProps
}
