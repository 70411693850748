import React, {useEffect, useState} from 'react'
import {
    getEmailAvailability, getMember,
    saveEmailAvailability,
    validateEmailAvailability,
    signMemberOut
} from '@vista/omnichannel-components-domain'

import {FooterNewsletterPropsFromBe} from '../../types'
import {getLocalStorage, member} from '../../utils'
import {
    CustomerDetailsFormData
} from "@vista/omnichannel-components-ui/lib/src/lib/components/customer-details-form/types/data";
import {observer} from "mobx-react";

interface IFooterNewsletter extends FooterNewsletterPropsFromBe {
    useStoredCustomerDetailsEmail?: boolean
}

export const FooterNewsletterComponent: React.FC<IFooterNewsletter> = observer(({ useStoredCustomerDetailsEmail = false, ...beProps}) => {
    const [processing,setProcessing] = useState<boolean>(false);
    const [loggedInEmail, setLoggedInEmail] = useState<string>('')
    const [inputValue, setInputValue] = useState<string>('')

    const memberDetails = getMember();

    useEffect(() => {
        if(memberDetails) {
            setLoggedInEmail(memberDetails.credentials.email)
        }
    }, [memberDetails])

    useEffect(() => {
        if(member.isSignedIn() && !useStoredCustomerDetailsEmail) {
            setInputValue(loggedInEmail)
        }
    },[loggedInEmail])

    useEffect(() => {
        const orderCustomerDetails:CustomerDetailsFormData | null = getLocalStorage('order-customer-details');
        if(useStoredCustomerDetailsEmail && orderCustomerDetails) {
            setInputValue(orderCustomerDetails.email)
        }
    },[])

    const onSubmit = e => {
        e.preventDefault();
        (async () => {
            // If logged in go to communication preference url
            if(member.isSignedIn() && inputValue === loggedInEmail) {
                window.location.href = `${beProps.config.communicationPreferencesUrl}?email=${inputValue}`
                return;
            }
            try {
                setProcessing(true);
                const emailValidity = await validateEmailAvailability(inputValue);
                await saveEmailAvailability(inputValue, emailValidity)
                const { isAvailable } = await getEmailAvailability();
                setProcessing(false);
                const emailExists = !isAvailable;

                /*
                * If user is signed in but subs to newsletter with other email that exists, sign user out and redirect
                * login modal. Once user signs in, it goes to the comms page.. (weird experience)
                * */
                if(member.isSignedIn() && emailExists && inputValue !== loggedInEmail) {
                    await signMemberOut()
                    window.location.href = `${beProps.config.communicationPreferencesUrl}?email=${inputValue}`
                    return;
                }

                // if not logged in but email exists go to login in modal then go to login in modal then communication preference page
                if(emailExists) {
                    // when hitting this url, and you're not logged in. Modal is opened
                    window.location.href = `${beProps.config.communicationPreferencesUrl}?email=${inputValue}`
                    return;
                } else {
                    // go to sign up page and check subscribe to newsletter. Member must be signed out for registration to work
                    if(member.isSignedIn()) {
                        await signMemberOut()
                    }
                    window.open(`${beProps.config.signUpUrl}?email=${e.target.email.value}&subToNewsletters=true`, "_blank")
                }
            } catch (e) {
                setProcessing(false)
            }
        })()
    }

    const onInputChange = e => setInputValue(e.target.value)

    return (
        <form className="newsletter-form" id="footer-newsletter-form" onSubmit={onSubmit}>
            <h5 className="newsletter-form__title">{beProps.displayText.title}</h5>
            <input
                required={true}
                className="newsletter-form__input"
                type="Email"
                name="email"
                value={inputValue}
                onChange={onInputChange}
                placeholder={beProps.displayText.emailPlaceholder}
            />
            <div className="newsletter-form__button">
                <button type="submit" disabled={processing}>
                    {beProps.displayText.signUpButtonLabel}
                </button>
            </div>
        </form>
    )
})

export default FooterNewsletterComponent