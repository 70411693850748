export const deleteUndefinedProperties = (inputObject) => {
    const deleteUndefined = (obj) => {
        for (const key in obj) {
            if (obj[key] === undefined) {
              delete obj[key];
            }
            else if(typeof obj[key] === 'object') {
                deleteUndefined(obj[key])
            }
          }
    }
    return deleteUndefined(inputObject)
}