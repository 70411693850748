import React, { useContext } from 'react'
import { MemberAccContext } from '../../context'
import {
    MemberJourneyListComponent,
    MemberTransactionListComponent,
    TransactionTotalsMode,
} from '@vista/omnichannel-components-ui'
import { activeBookingProps, nextBookingProps, oneActiveBookingProps, recentBookingProps } from './props'

const cdnOptions = {
    filmThumbnailImage: { width: 500 },
    itemImage: { width: 500 },
}

/**
 * MemberAccActiveBookingComponent.
 */
export const MemberAccActiveBookingComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    return (
        <>
            <MemberJourneyListComponent {...activeBookingProps(ctx.MemberJourneyList)} cdnOptions={cdnOptions} />
        </>
    )
}

/**
 * Active journeys
 * These include orders with tickets for an upcoming or in-progress showtime, and concession only orders.
 */
export const MemberAccOneActiveBookingComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    return (
        <>
            <MemberJourneyListComponent {...oneActiveBookingProps(ctx.MemberJourneyList)} cdnOptions={cdnOptions} />
        </>
    )
}

/**
 * The Next Journey
 * A highlighted journey (singled out from the other active journeys)
 * This is the active journey with the soonest showtime (this will usually be the next showtime upcoming, but could be a past showtime if a journey was created where the items were not collected)
 * If there is no data available or there is an error, nothing will be rendered
 */
export const MemberAccNextBookingsComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    return (
        <>
            <MemberJourneyListComponent {...nextBookingProps(ctx.MemberJourneyList)} cdnOptions={cdnOptions} />
        </>
    )
}

/**
 * Recent journeys
 * These include orders where the showtime has passed, or an item-only journey that has been marked as collected (or has expired as per configured settings)
 */
export const MemberAccRecentBookingsComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    return (
        <>
            <MemberJourneyListComponent {...recentBookingProps(ctx.MemberJourneyList)} cdnOptions={cdnOptions} />
        </>
    )
}

/**
 * The Member Transaction List component renders a loyalty member’s past points earnings and redemptions.
 */
export const MemberAccTransactionsComponent: React.FC = () => {
    return (
        <>
            <MemberTransactionListComponent
                config={{
                    numberOfTransactionsToLoad: 20,
                    transactionTotalsMode: TransactionTotalsMode.Price,
                }}
                displayText={{
                    tableColumnTotalsHeaderLabel: 'TOTAL',
                }}
                assets={{
                    noTransactionsIconName: 'curzon-card',
                    failedToLoadDataIconName: 'curzon-card',
                }}
            />
        </>
    )
}
