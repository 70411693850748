import {
    ButtonColor,
    ButtonIconPosition,
    ButtonSize,
    CaptchaHandles,
    FieldDisplayText,
    FieldFormatType,
    MemberSignInFormProps
} from '@vista/omnichannel-components-ui'
import { MovioTracker, log, resetCaptcha } from './../../utils'
import { defaultMemberSignInFormEmailFieldDisplayText, defaultMemberSignInFormPasswordFieldDisplayText } from '@vista/omnichannel-components-ui/lib/src/lib/components/member-sign-in-form-field/types/display-text'

import { ISignInForm, ISignInBtn, ISignInEvents } from '../../types'
import {defaultMemberSignInFormProps} from '@vista/omnichannel-components-ui/lib/src/lib/components/member-sign-in-form/types/props'
import { RefObject } from 'react'
import { InitData } from '../../initialise/types/initialise';

export const loginCheckExpiryTimeInMins = 10
export const loginCheckExpiryTimeInSecs:number = 60 * loginCheckExpiryTimeInMins
export const loginCheckExpiryTimeInMs:number = 1000 * loginCheckExpiryTimeInSecs

export function formPropsDisplayText(props: ISignInForm): MemberSignInFormProps['displayText'] {
    const signInFail:SignInFail = JSON.parse(sessionStorage.getItem("signInFail"))
    const isExpired:boolean = signInFail && (new Date().getTime() - new Date(signInFail?.firstFailTime).getTime()) > loginCheckExpiryTimeInMs
    const isPenultimateSignInFailed:boolean = signInFail?.attemptNumber > 1 && !isExpired

    const returnProps:MemberSignInFormProps['displayText'] =  {
        ...props.displayText,
        errorApiMemberLockedMessage: props.displayText.errorApiMemberLockedMessage || defaultMemberSignInFormProps.displayText.errorApiMemberLockedMessage, // Todo - update backend to include this prop
        errorApiIncorrectSignInCredentialsMessage: isPenultimateSignInFailed 
        ? "You have 1 login attempt remaining. Another failed attempt will temporarily block access to your account."
        : props.displayText.errorApiIncorrectSignInCredentialsMessage
    }

    /*@ts-ignore*/
    delete returnProps.buttonSubmitLabel

    //should be submitButtonLabel instead of buttonSubmitLabel

    return returnProps
}

type FieldName = 'Email' | 'Password' 
export function formFieldsDisplayText(props: ISignInForm): { [key in FieldName]: FieldDisplayText } {
    const Email: FieldDisplayText = {
        label: props.displayText.fieldEmailLabel || defaultMemberSignInFormEmailFieldDisplayText.label,
        placeholder: props.displayText.fieldEmailPlaceholder || 'Email',
        requiredMessage: props.displayText.errorEmailRequiredMessage || defaultMemberSignInFormEmailFieldDisplayText.requiredMessage,
    }
    const Password: FieldDisplayText = {
        label: props.displayText.fieldPasswordLabel || defaultMemberSignInFormPasswordFieldDisplayText.label,
        placeholder: props.displayText.fieldPasswordPlaceholder || 'Password',
        requiredMessage: props.displayText.errorPasswordRequiredMessage || defaultMemberSignInFormPasswordFieldDisplayText.requiredMessage,
    }

    return { Email, Password }
}

export function formPropsConfig(props: ISignInForm): MemberSignInFormProps['config'] {
    const returnProps:MemberSignInFormProps['config'] =  {
        ...props.config,
        cancelButton: {
            show: true,
            size: ButtonSize.Small,
            color: ButtonColor.Primary,
            iconPosition: ButtonIconPosition.Left,
        },
        submitButton: {
            show: true,
            size: ButtonSize.Small,
            color: ButtonColor.Primary,
            iconPosition: ButtonIconPosition.Right,
        },
        validation: {
            email: {
                type: FieldFormatType.Email,
            },
            username: {
                type: FieldFormatType.Text,
            },
            password: {
                type: FieldFormatType.Text,
                required: true,
            },
            remember: {
                type: FieldFormatType.Other,
                required: false,
            },
            captcha: {
                type: FieldFormatType.Other,
                required: true,
            },
        },
    }
    /*@ts-ignore*/
    delete returnProps.mode

    return returnProps
}

declare global {
    interface Window {
        captchaRef: RefObject<CaptchaHandles>;
    }
    // hack to get around typescript linter, I can't use the InitData interface here because it doesn't  match with the actual data
    const initData: any; 
}

interface SignInFail {
    firstFailTime:number
    attemptNumber:number
}

export function formPropsEvents(props: ISignInEvents): MemberSignInFormProps['events'] {
    const movioTracker = new MovioTracker()

    const setSignInFail = (data) => {
        sessionStorage.setItem("signInFail", JSON.stringify(data))
    }
    
    return {
        onBeforeSubmit: () => {
            log('onBeforeSubmit', 'triggered')
        },
        onAfterSuccessfulSubmit: async () => {
            log('onAfterSuccessfulSubmit', 'success')
            if (props.signInHandler) await props.signInHandler()
            if (props.closeHandler) props.closeHandler()
            sessionStorage.removeItem("signInFail")

            movioTracker.initiateLogin()
        },
        onAfterFailedSubmit: (e: Error) => {
            const signInFail:SignInFail = JSON.parse(sessionStorage.getItem("signInFail"))
            const isExpired:boolean = signInFail && (new Date().getTime() - new Date(signInFail?.firstFailTime).getTime()) > loginCheckExpiryTimeInMs

            // create signInFail session storage on first signIn failure or if expired
            if(!signInFail || isExpired) {
                setSignInFail({firstFailTime: new Date().getTime(), attemptNumber: 1})
            }
            // signInFail has not expired. Check how many attempts there have been
            else if(signInFail.attemptNumber === 1) {
                setSignInFail({firstFailTime: signInFail.firstFailTime, attemptNumber: 2})
            }
            else if(signInFail.attemptNumber === 2) {
                setSignInFail({firstFailTime: signInFail.firstFailTime, attemptNumber: 3})
            }
            
            
            resetCaptcha()
            window?.captchaRef?.current?.reset()
            log('onAfterFailedSubmit', e)
        },
    }
}
