import { SitePickerProps } from "@vista/omnichannel-components-ui";

export interface ModalSitePickerPropsType {
    events?: ModalSitePickerEvents
    show?: boolean
    displayText: ModalSitePickerDisplayText
    sitePickerComponentProps: SitePickerProps
    siteIds?:string[]
    className?: string
}

export interface ModalSitePickerDisplayText {
    closeModalButtonText?: string
    headerText?: string
}

export interface ModalSitePickerEvents {
    onClose?: () => void
    onSitesChanged?: () => void
}

export const defaultModalSitePickerDisplayText:ModalSitePickerDisplayText = {
    closeModalButtonText: "Close",
    headerText: "Select Cinemas"
}