import { GiftShopCustomDetailsPropsFromBe } from '../../types'

export const GiftShopCustomDetailsComponent: React.FC<GiftShopCustomDetailsPropsFromBe> = props => {
    return (
        <div className="gift-shop-custom-details">
            <h1 className="gift-shop-custom-details__title">{props.displayText.title}</h1>
            <div className="gift-shop-custom-details__image">
                <img src={props.displayText.giftShopImageCrops.imageCropDetails.src} />
            </div>
        </div>
    )
}

