import React, { useContext, useEffect, useState } from 'react'

import { MemberSitePreferences } from '@vista-digital/ocapi-types/v1'

import { Button, Dropdown } from '@vista/omnichannel-components-ui'
import { getSiteById, getSites, loadMember, updateFavoriteCinemas } from '../../utils'
import { MemberAccContext } from '../../context'

export const MemberAccFavoriteCinemasComponent: React.FC = () => {
    const [optionsList, setOptionList] = useState([])
    const [preferredSite1, setPreferredSite1] = useState(null)
    const [preferredSite2, setPreferredSite2] = useState(null)
    const [preferredSite3, setPreferredSite3] = useState(null)
    const ctx = useContext(MemberAccContext)

    const { displayText } = ctx.FavoriteCinemas

    useEffect(() => {
        getSites().then(sites => {
            if (optionsList.length < 2) {
                const optionsListBuilder = [
                    {
                        value: '',
                        text: 'Select an Option',
                    },
                    ...sites,
                ]
                setOptionList(optionsListBuilder)
            }
        })

        loadMember().then(member => {
            const previousPreferredSites = member.personalDetails.preferences.sites.preferredSiteIds
            const preferredSitesToSend = []

            const dropdownDefaultSetters = [setPreferredSite1, setPreferredSite2, setPreferredSite3]

            if (previousPreferredSites.length) {
                previousPreferredSites.forEach((siteId, index) => {
                    getSiteById(siteId).then(site => {
                        preferredSitesToSend.push({
                            value: siteId,
                            text: site.name.text,
                        })
                    })

                    dropdownDefaultSetters[index](siteId)
                })
            }
        })
    }, [])

    const options = optionsList

    const handleSubmission = () => {
        const selectedSiteIds = [preferredSite1, preferredSite2, preferredSite3];
        const newSelectedSiteIds = []

        selectedSiteIds.forEach((siteId, index) => {
            if (index === 0 && siteId) newSelectedSiteIds.push(siteId)
            else if (index === 1 && siteId && siteId !== selectedSiteIds[0]) newSelectedSiteIds.push(siteId)
            else if (index === 2 && siteId && siteId !== selectedSiteIds[0] && siteId !== selectedSiteIds[1]) newSelectedSiteIds.push(siteId)
        })

        const newDetails: MemberSitePreferences = {
            preferredSiteIds: newSelectedSiteIds,
        }

        updateFavoriteCinemas(newDetails)
    }

    return (
        <div className="member-favourite-cinemas">
            <h3 className="member-preferences__heading">{displayText.subheading}</h3>
            <div className="member-favourite-cinemas__subheading">{displayText.text}</div>

            <div className="member-favourite-cinemas__form">
                <Dropdown
                    options={options}
                    value={preferredSite1}
                    placeholder={displayText.selectCinemaPlaceholder}
                    onChangeValue={setPreferredSite1}
                />
                <Dropdown
                    options={options}
                    value={preferredSite2}
                    placeholder={displayText.selectCinemaPlaceholder}
                    onChangeValue={setPreferredSite2}
                />
                <Dropdown
                    options={options}
                    value={preferredSite3}
                    placeholder={displayText.selectCinemaPlaceholder}
                    onChangeValue={setPreferredSite3}
                />
                <Button onClick={() => handleSubmission()}>{displayText.buttonText}</Button>
            </div>
        </div>
    )
}
