import React from 'react'

import { DisplayTextLabel, getOrderExpiry } from '@vista/omnichannel-components-domain'
import {Countdown} from '@vista/omnichannel-components-ui'
import {initReactComponent} from '../../utils'


export const CountDownComponent = () => {

    const orderExpiry = getOrderExpiry()
    const labelFormat: DisplayTextLabel = { text: 'TIME REMAINING: ${minutes}:${seconds}' };
    return (
        <>
        {orderExpiry && <Countdown targetDate={orderExpiry} labelFormat={labelFormat}/>}
        </>
    )
}

export const countdownLoader = (): void => {
    if(!document.getElementById("countdown")) return
    initReactComponent({
        Component: CountDownComponent,
        id: 'countdown',
        props: {},
    })
}