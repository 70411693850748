import { MemberCard, MemberContactMethod, MemberCustomPreference, MemberPersonalDetails, MemberSitePreferences } from '@vista-digital/ocapi-types/v1'
import { loadMember as domainLoadMember, getMember, isMemberSignedIn, signMemberOut, updateMemberPersonalDetails, NotificationType } from '@vista/omnichannel-components-domain'

import { Member } from '@vista-digital/ocapi-types/v1';
import { goHome } from '../components'
import { addNotification } from '../utils'

export const member = {
    /** load and get member */
    load: loadMember,
    getEmail: getEmail,
    getCards: getCards,
    getPrimaryCard: getPrimaryCard,
    /** sign out and go to homepage */
    signOut: signOutMemberAndGoHome,
    isSignedIn: isSignedIn,
    isSignedInAsync: isSignedInAsync,
    /** mutate personalDetails in loyaltyDomain */
    updateFavoriteCinemas: updateFavoriteCinemas,
    /** mutate personalDetails in loyaltyDomain */
    updatePreferences: updatePreferences,
}

export async function loadMember(): Promise<Member | undefined> {
    try {
        const member:Member | undefined = getMember() ??  await domainLoadMember()
        return member
    } catch (e) {
        return undefined
    }
}

export async function signOutMemberAndGoHome(): Promise<void> {
    if(getMember())
        await signMemberOut()
        goHome()
}

//TODO: Clean up these functions to send new details

export async function updateFavoriteCinemas(cinemaList: MemberSitePreferences): Promise<void> {
    const member = await loadMember()
    if(!member) return

    const newDetails: MemberPersonalDetails = {
        ...member.personalDetails,
        preferences: {
            ...member.personalDetails.preferences,
            sites: {
                ...cinemaList
            }
        }
    }

    updateMemberPersonalDetails(newDetails, undefined).then(() => {
        addNotification(
            {
                id: 'favourite-cinemas-submitted',
                messages: ['Your details have been submitted.'],
            },
            NotificationType.Success
        )
    })
    .catch(error => {
        console.error('Error:', error)

        addNotification(
            {
                id: 'favourite-cinemas-failed',
                messages: ['Failed to submit.'],
            },
            NotificationType.Error
        )
    })
}

async function getCards(): Promise<MemberCard[]> {
    await loadMember()
    return getMember()?.cards ?? []
}
async function getPrimaryCard(): Promise<MemberCard> {
    const cards = await getCards()
    return cards.find(card => card.isPrimary)
}

async function getEmail(): Promise<string> {
    await loadMember() 
    const email = getMember()?.credentials?.email ?? ''
    return email
}

export interface MemberPreferencesToSend {
    contactMethods: MemberContactMethod[]
    customPreferences: MemberCustomPreference[]
}

export async function updatePreferences(preferences: MemberPreferencesToSend): Promise<void> {
    const member = await loadMember()
    if(!member) return

    const newDetails: MemberPersonalDetails = {
        ...member.personalDetails,
        preferences: {
            ...member.personalDetails.preferences,
            contact: {
                ...member.personalDetails.preferences.contact,
                contactMethods: preferences.contactMethods
            },
            customPreferences: preferences.customPreferences
        }
    }

    updateMemberPersonalDetails(newDetails, undefined).then(() => {
        addNotification(
            {
                id: 'member-preferences-submitted',
                messages: ['Your details have been submitted.'],
            },
            NotificationType.Success
        )
    })
    .catch(error => {
        console.error('Error:', error)

        addNotification(
            {
                id: 'member-preferences-failed',
                messages: ['Failed to submit.'],
            },
            NotificationType.Error
        )
    })
}

export function isSignedIn(): boolean {
    return isMemberSignedIn()
}

export async function isSignedInAsync(): Promise<boolean> {
    const member = await loadMember()
    return !!member
}
