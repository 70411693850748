import { MemberJourneyListPropsFromBe } from '../../../../types'
import { MemberJourneyListProps, FilmThumbnailMediaType, MemberJourneyListType } from '@vista/omnichannel-components-ui'
import { assets, cdnOptions, defaultDisplayText, events } from './.'

export function nextBookingProps(props: MemberJourneyListPropsFromBe): MemberJourneyListProps {
    const config: MemberJourneyListProps['config'] = {
        filmMediaType: FilmThumbnailMediaType.Still,
        listType: MemberJourneyListType.Active,
        numberOfJourneysToLoad: 6,
        showMessageForEmptyList: true,
    }

    const displayText: MemberJourneyListProps['displayText'] = {
        ...defaultDisplayText,
        ...props.displayText
    }

    return { config, displayText, assets, cdnOptions, events: events(props)}
}

