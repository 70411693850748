import {
    Modal,
} from '@vista/omnichannel-components-ui'
import { IGuestSignIn, ISignIn, ISignInBtn} from '../../types'
import { OpenLoginModal, member } from '../../utils'
import React, { useState } from 'react'
import {SignIn} from './'

import cls from 'classnames'

export const ButtonSignIn: React.FC<ISignInBtn> = props => {
    if (!props.show) return null

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const openModal = () => {
        setIsOpen(true)
    }
    const closeModal = () => {
        const isSignedIn = member.isSignedIn()
        setIsOpen(false)
        // All member pages have this class
        const isMemberAccountPageElement = !!document.querySelector('.member-acc-page');
        if (isMemberAccountPageElement && !isSignedIn) {
            window.location.assign('/')
        }
    }

    OpenLoginModal.register(openModal)

    const svgCls = cls('c-icon', 'c-icon__user', {
        'c-icon__round--grey': true,
        'c-icon__round--pink': false,
    })

    const signInProps:ISignIn = {
        ...props,
        displayText: {
            forgotPasswordName: props.modal.displayText.forgotPasswordName
        }
    }

    return (
        <>
            <button className="btn--icon main-nav__extra__user__signin" onClick={openModal}>
                <svg className={svgCls}>
                    <use xlinkHref="#c-icon_user" />
                </svg>
            </button>
            <Modal
                show={isOpen}
                onClose={closeModal}
                className="modal__login"
                heading={props.modal.displayText.signInTitle}
                closeButtonIconName="cross"
            >
                <p>{props.modal.displayText.signInText}</p>
                <SignIn {...signInProps} />
                <GuestComponent {...props.guest} />
                
            </Modal>
        </>
    )
}


export const GuestComponent: React.FC<IGuestSignIn> = props => {
    const { guestSignInTitle, bookingFeeText, joinNowCTAText } = props.displayText
    const { joinNowCTALink, joinNowCTATarget } = props.config
    return (
        <div className="guest v-sign-in-form">
            <h4 className="center">{guestSignInTitle}</h4>
            <p>{bookingFeeText}</p>
            <a className="btn--black center" href={joinNowCTALink} target={joinNowCTATarget}>
                {joinNowCTAText}
            </a>
        </div>
    )
}
