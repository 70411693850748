import {
    MovioTracker,
    cancelOrder,
    getLocalStorage,
    initReactComponent,
    removeLocalStorage,
    setLocalStorage,
    usePageVisibility,
    getQueryParameterByName,
} from '../../utils'
import React, { useEffect, useState } from 'react'
import { clearOrderSubscriptionSelection, getOrder, getOrderSelections, getShowtimeById, hasOrderBeenCreated, hasOrderExpired } from '@vista/omnichannel-components-domain'

import { InProgressOrder, InProgressStandardOrder, OrderType } from '@vista-digital/ocapi-types/v1'
import { Modal } from '@vista/omnichannel-components-ui'
import { goHome } from '..'

export const abandonOrderLoader = (): void => {
    initReactComponent<any>({
        Component: () => <AbandonOrderComponent />,
        id: 'main-abandon-order',
        props: {},
    })
}

export const AbandonOrderComponent: React.FC = () => {
    const [isOrderExpiredModalVisible, setOrderExpiredModal] = useState<boolean>(false)
    const [isAbandonOrderModalVisible, setAbandonOrderModal] = useState<boolean>(false)
    const [orderId, setOrderId] = useState<string>(null)
    const [initialized, setInitialized] = useState(false)

    const isTabVisible = usePageVisibility()

    const purchasingFlowPattern = /ticketing|gift-shop|sign-up-root|membership/gi

    useEffect(() => {
        inProgressOrderCheck()
    }, [])

    useEffect(() => {
        if (isTabVisible && initialized) {
            const cancelledOrderId = getLocalStorage('cancelled-order-id')
            const newOrderId = getLocalStorage('new-order-id')

            if (window.location.href.match(purchasingFlowPattern)) {
                if (cancelledOrderId && cancelledOrderId == orderId) {
                    setOrderExpiredModal(true)
                    removeLocalStorage('cancelled-order-id')
                }
                if (newOrderId && newOrderId != orderId) {
                    setOrderExpiredModal(true)
                }
            }
        }
    }, [isTabVisible])

    const inProgressOrderCheck = () => {
        if(!getQueryParameterByName("noPopup")) {
            const orderInprogress = hasOrderBeenCreated() && !hasOrderExpired()
            const order = getOrder()
            const subscriptionSelection = getOrderSelections().subscription

            if (orderInprogress && !!subscriptionSelection && order.type !== OrderType.Subscription) {
                // After selecting a subscription and then abandoning to instead book a film, the price of the subscription shows in the ticket selection
                // Resolves https://vistadigital.atlassian.net/browse/CRZN-1635
                clearOrderSubscriptionSelection()
            }

            const hasCostedItems = !!order?.totalPrice?.valueIncludingTax

            if (orderInprogress && hasCostedItems) {
                setOrderId(order.id)
                setLocalStorage('new-order-id', order.id)
                if (!window.location.href.match(purchasingFlowPattern)) {
                    const continueOrderId = getLocalStorage('continue-order-id')
                    if (continueOrderId != order.id) setAbandonOrderModal(true)
                }
                if ((order as InProgressStandardOrder)?.showtimes) handleAbandonOrderTracking(order as InProgressStandardOrder)
            } else {
                removeLocalStorage('new-order-id')
            }

            setInitialized(true)
        }
    }

    const handleAbandonOrderTracking = (order: InProgressStandardOrder) => {
        const showtimeId = order.showtimes[0]?.showtimeId
        if (!showtimeId) {
            abandonOrderTimer()
            console.error("ShowtimeId is undefined, abandoning order")
        }
        const showtime = getShowtimeById(showtimeId)

        if (!window.location.href.match(purchasingFlowPattern)) abandonOrderTracker(showtime.filmId)
        if (window.location.href.match(purchasingFlowPattern)) {
            abandonOrderTimer(showtime.filmId)
        }
    }

    const abandonOrderTimer = (filmId?: string) => {
        const orderChecker = setInterval(async () => {
            const hasExpired = hasOrderExpired()

            if (hasExpired) {
                if(filmId) abandonOrderTracker(filmId)
                clearInterval(orderChecker)
            }
        }, 1000)
    }

    function abandonOrderTracker(filmId: string) {
        const movioTracker = new MovioTracker()

        movioTracker.abandonOrder(filmId)
    }

    const modalAbandonOrder = () => {
        cancelOrder(orderId)
        setAbandonOrderModal(false)
    }
    const modalContinueOrder = () => {
        setLocalStorage('continue-order-id', orderId)
        setAbandonOrderModal(false)
    }

    return (
        <>
            <Modal
                className="v-modal__order-expired"
                show={isOrderExpiredModalVisible}
                onClose={goHome}
                heading="Booking expired"
            >
                <p>You have started another booking so this one has expired</p>
                <button className="btn--black center order-expired-button" onClick={goHome}>
                    Go to homepage
                </button>
            </Modal>

            <Modal
                className="v-modal__order-expired"
                show={isAbandonOrderModalVisible}
                canBeClosed={false}
                onClose={goHome}
                heading="booking in progress"
            >
                <p className="modal-description">
                    You already have a booking in progress. Do you want to abandon your booking or continue browsing?
                </p>
                <button className="btn--pink" onClick={modalAbandonOrder}>
                    Abandon Your Booking
                </button>
                <button className="btn--black" onClick={modalContinueOrder}>
                    Continue Browsing
                </button>
            </Modal>
        </>
    )
}
