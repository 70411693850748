import { NotificationDetails, NotificationType, PartialExceptFor } from '@vista/omnichannel-components-domain'

export function getSessionStorage<T>(key: string): T {
    const item = sessionStorage.getItem(key)
    return item ? (JSON.parse(item) as T) : null
}

export function setSessionStorage<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeSessionStorage(key:string): void {
    sessionStorage.removeItem(key)
}

export class SessionStorageNotification {
    static readonly key = 'v-notification'

    public details: PartialExceptFor<NotificationDetails, 'id' | 'messages'>
    public type: NotificationType
}
