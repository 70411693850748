export const filmDetailsButtons = (): void => {
    const filmDetailsComponent = document.getElementsByClassName('v-film-details')[0]
    const filmDetailsBanner = document.getElementsByClassName('v-film-details-banner')[0]
    
    const trailerButton = document.getElementsByClassName('v-film-trailer-button')[0]

    const watchOnDemandButton = document.getElementsByClassName('watch-on-demand__button')[0]
    const showtimesButton = document.getElementsByClassName('v-film-details-banner__action-button')[0]
    const watchlistButton = document.getElementsByClassName('v-film-watchlist-button')[0]
    const newButtonLocation = document.getElementsByClassName('v-film-summary__primary')[0]

    if (filmDetailsComponent) {
        if (watchlistButton) newButtonLocation.prepend(watchlistButton)
        if (watchOnDemandButton) newButtonLocation.prepend(watchOnDemandButton)
        if (showtimesButton) newButtonLocation.prepend(showtimesButton)
        if (trailerButton) filmDetailsBanner.prepend(trailerButton)
    }
}