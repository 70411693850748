import { BarcodeType, ButtonColor, ButtonSize, MemberViewCardButtonProps } from '@vista/omnichannel-components-ui'

import { MemberViewCardButtonPropsFromBe } from '../../../types'
import { defaultMemberViewCardButtonProps } from '@vista/omnichannel-components-ui/lib/src/lib/components/member/view-card-button/types/props'

export function memberViewCardButtonProps(props: MemberViewCardButtonPropsFromBe): MemberViewCardButtonProps {
    
    const returnProps =  {
        displayText: {
            ...props.displayText,
        },
        config: {
            color: ButtonColor.Secondary,
            size: ButtonSize.Small,
            barcodeType: BarcodeType.QrCode,
            showMemberPhoto: true,
        },
        cdnOptions: defaultMemberViewCardButtonProps.cdnOptions
    }

    return returnProps
}