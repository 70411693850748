import { OrderCartComponent } from '@vista/omnichannel-components-ui'
import { OrderCartPropsFromBe } from '../../types'
import React from 'react'
import { orderCartProps } from './props'
import { useShowtimeContext } from '@vista/omnichannel-components-ui';

export const FbBasketComponent: React.FC<OrderCartPropsFromBe> = props => {
    const { showtime } = useShowtimeContext();
    return <>{showtime && <OrderCartComponent {...orderCartProps(props)} />}</>
}
