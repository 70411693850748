import { SwiperCarousel, SwiperOptions } from '../../../types'
import { swiperFacade, swiperState } from '../swiperFacade'
import { isMobile } from './helper'

export const quoteCarousel = ({ config }: SwiperCarousel): void => {
    const { className, delay } = config
    const state = swiperState(delay)

    const options: SwiperOptions = {
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        ...state.autoplay,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-carousel__pagination',
        },
        autoplay: isMobile(),
    }

    const element = document.querySelector<HTMLElement>(className)
    swiperFacade({ element, options })
}
