import React from 'react'
import { CarouselHeaderPropsType } from '../../../types'

export const Header: React.FC<CarouselHeaderPropsType> = props => {
    const hasCTALink:boolean = props.ctaLink && !!props.ctaLink.url && !!props.ctaLink.name
    return (
        <div className="events-carousel__header width--normal">
            <div className="events-carousel__header__title">{props.title}</div>
            { hasCTALink &&
                <div className="events-carousel__header__link" >
                    <a className="events-carousel__header__link-text" href={props.ctaLink.url}>
                        {props.ctaLink.name}
                    </a>
                </div>
            }
        </div>
    )
}
