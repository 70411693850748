import { WorkflowData } from "../initialise/types"

enum PagesName {
    seatPicker = 'seatPicker',
    ticketPicker = 'ticketPicker',
    foodAndBeverage = 'foodAndBeverage',
    customerDetails = 'customerDetails',
    payment = 'payment',
    orderSummary = 'orderSummary',
    any = 'any'
}

type PagesIdxInWorkflow = {
    [key in PagesName]: number
}

type Pages = {
    [key in PagesName]: boolean
}

function bookingFlow(workflow: WorkflowData): Pages {
    const pagesIdx: PagesIdxInWorkflow = {
        seatPicker: 0,
        ticketPicker: 1,
        foodAndBeverage: 2,
        customerDetails: 3,
        payment: 4,
        orderSummary: 5,
        any: NaN,
    }

    const pages: Pages = {
        seatPicker: false,
        ticketPicker: false,
        foodAndBeverage: false,
        customerDetails: false,
        payment: false,
        orderSummary: false,
        any: false
    }

    Object.keys(pagesIdx).forEach((pageName: PagesName) => {
        pages[pageName] = workflow.ticketingWorkflow?.currentIndex === pagesIdx[pageName]
    })

    pages.any = !!workflow.ticketingWorkflow?.currentIndex

    return pages
}

export const isPage = {
    bookingFlow,
}

export function ticketingPageName(workflow: WorkflowData): string {
    return workflow.ticketingWorkflow.pages[workflow.ticketingWorkflow.currentIndex].name
}
