import { EventShowtimePickerIdsType, EventShowtimePickerPropsFromBe, EventShowtimePickerPropsType } from './../../types'
import {
    FilmThumbnailMediaType,
    ShowtimePickerAssets,
    ShowtimePickerConfig,
    ShowtimePickerDisplayText,
    ShowtimePickerEvents,
} from '@vista/omnichannel-components-ui'

import { UrlFactory } from '../../utils'

export function CreateEventShowtimePickerProps(
    props: EventShowtimePickerPropsFromBe,
    ids: EventShowtimePickerIdsType
): EventShowtimePickerPropsType {
    return {
        eventId: ids.eventId,
        siteId: ids.siteId,
        displayText: displayText(props),
        config: config(props),
        assets: assets(),
        cdnOptions: cdnOptions(),
        events: events(props),
    }
}

function displayText(props: EventShowtimePickerPropsFromBe): ShowtimePickerDisplayText {
    const isVenuesPage = window.location.pathname.includes('/venues/')

    const {displayText} = props
    /*@ts-ignore*/
    delete displayText.showtimeSoldOutLabel

    /*@ts-ignore*/
    delete displayText.noShowtimesForSiteAndFilmWithAdvanceBookingPeriodMessage

    const defaultDisplayText: ShowtimePickerDisplayText = {
        showtimeTimeFormat: 'h:mm',
        showtimeAmPmFormat: 'A',
        showtimeUnavailableAdvanceBookingPeriodNotificationFormat:
            'Coming soon. Tickets on sale at ${siteName} from ${startsAt|d|Do of MMMM, h:mm A}. Please come back later.',
        showtimeUnavailableAdvanceBookingPeriodTodayNotificationFormat:
            'Coming soon. Tickets on sale at ${siteName} today from ${startsAt|d|h:mm A}. Please come back later.',
        commonAttributesListLabel: 'All Showtimes:',
        attributeLegendLabel: 'Showtime Attributes',
        attributeLegendViewButtonLabel: 'What do these mean?',
        attributeLegendModalHelpText: undefined,
        runtimeLabel: 'Runtime',
        businessDateFormat: '${businessDate|d|dddd D MMMM}',
        noShowtimesForFilterMessage: 'There are no showtimes that match the selected filters.',
        noShowtimesForFilterClearButtonLabel: 'Clear filters',
        noShowtimesForFilmMessage:
            'There are no showtimes on this date for "${selectedFilmTitle}" at your selected cinemas.\nPlease select another cinemaa.',
        noShowtimesForSiteAndFilmMessage: 'This movie is no longer available at this location.',
        noShowtimesForAnyDateTitle: 'No showtimes available.',
        noShowtimesForAnyDateMessage: 'Please try another selection.',
        advanceBookingPeriodForSiteNotificationFormat:
            'Tickets on sale at ${siteName} from ${startsAt|d|Do of MMMM, h:mm A}.',
        advanceBookingPeriodForSiteTodayNotificationFormat:
            'Tickets on sale at ${siteName} today from ${startsAt|d|h:mm A}.',
        failedToLoadDataTitle: 'Sorry, something went wrong.',
        failedToLoadDataMessage: 'Refresh your browser to try loading your selections again.',
        playTrailerButtonLabel: 'Play trailer',
        failedToLoadTrailerRetryButtonLabel: 'Retry',
        failedToLoadTrailerTitle: 'Sorry, something went wrong.',
        filmActionLinkText: 'Show full details',

        noSitesSelectedTitle: isVenuesPage ? 'No Event selected' : 'No cinemas selected.',
        noSitesSelectedMessage: isVenuesPage
            ? 'Select an event from the options above'
            : 'Where would you like to see the movie?',

        noShowtimesGenericMessage: isVenuesPage
            ? 'This event is not playing at your selected venue.\nPlease select a different event.'
            : 'There are no showtimes on this date at your selected cinemas.\nPlease select another cinema.',
    }

    return {
        ...defaultDisplayText,
        ...displayText,
    }
}

function events(props: EventShowtimePickerPropsFromBe): ShowtimePickerEvents {
    const urlFactory = new UrlFactory(props.workflow)

    return {
        onShowtimeClicked: showtime => {
            window.location.assign(urlFactory.getSeatPickerDetailsUrl(showtime.id))
        },
        onFilmActionLinkClicked: film => {
            window.location.assign(urlFactory.getFilmDetailsUrl(film))
        },
    }
}

function assets(): ShowtimePickerAssets {
    return {
        failedToLoadDataIconName: 'curzon-warning',
        failedToLoadTrailerIconName: 'film-strip',
        noShowtimesIconName: 'film-strip',
        noSitesSelectedIconName: 'no-site-selected',
        playTrailerButtonIconName: 'play-circle',
    }
}

function cdnOptions() {
    return {
        censorRatingIcon: { height: 40 },
        filmThumbnailImage: { width: 500 },
        attributeIcon: { height: 200 },
        heroAttributeIcon: { height: 64 },
        promotedAttributeIcon: { height: 32 },
    }
}

function config(props: EventShowtimePickerPropsFromBe): ShowtimePickerConfig {
    const { config } = props

    return {
        ...config,
        showSiteDetails: true,
        showScreenName: true,
        filmDetails: {
            show: true,
            mediaType: FilmThumbnailMediaType.Still,
        },
        playTrailerButton: {
            show: config?.playTrailerButton?.show,
            showControls: true,
            autoPlay: true,
            muted: true,
            loop: true,
        }
    }
}
