import React from 'react'
import { OrderTotalsComponent as OccOrderTotalsComponent} from '@vista/omnichannel-components-ui'
import { orderTotalsProps } from './props'
import { OrderTotalsPropsFromBe } from '../../types'

export const OrderTotalsComponent: React.FC<OrderTotalsPropsFromBe> = props => {
    return (
        <>
            <OccOrderTotalsComponent {...orderTotalsProps(props)} />
        </>
    )
}
