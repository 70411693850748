import React, { createContext, useEffect } from 'react'

import { IMemberAccContextProps } from '../types'
import { MemberContextComponent } from '@vista/omnichannel-components-ui'
import {NotificationListComponent} from '../components'
import { OpenLoginModal } from '../utils'

export const MemberAccContext = createContext<IMemberAccContextProps | null>(null)

export const MemberAccContextProvider: React.FC<IMemberAccContextProps> = props => {
    useEffect(() => {
        OpenLoginModal.openAsync()
    }, [])

    // just in case workflow is undefined
    const { workflow = {}, ...restProps } = props.MemberContext || {}

    return (
        <MemberAccContext.Provider value={props}>
            <MemberContextComponent {...restProps}>{props.children}</MemberContextComponent>
            <NotificationListComponent />
        </MemberAccContext.Provider>
    )
}
