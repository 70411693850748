import { displayText, goToPrevPage } from '.'

import { Button } from '@vista/omnichannel-components-ui'
import React from 'react'
import { WorkflowNavigationPropsFromBe } from '../../types'

export const WorkflowNavigationComponent: React.FC<WorkflowNavigationPropsFromBe> = _props => {
    const prevClickHandler = () => {
        if (props.prevClickHandler) props.prevClickHandler()
        else goToPrevPage(props.workflow, props.skipPagesOnBack)
    }

    const props = {
        ..._props,
        displayText: displayText(_props),
    }

    const NextButton = () =>
        props.nextClickHandler ? (
            <Button
                loadingIconName="spinner"
                onClick={props.nextClickHandler}
                className="btn--pink"
                label={props.displayText.nextLabel}
                disabled={!!props.nextBtnDisabled}
                loading={props.isButtonLoading}
            ></Button>
        ) : null

    const BackButton = () => {
        if (props.hideBackButton) return null
        return (
            <div className="ticket-nav__btn--prev">
                <button className="btn--grey" onClick={prevClickHandler}>
                    {props.displayText.previousLabel}
                </button>
            </div>
        )
    }

    return (
        <div className={props.className ?? ''}>
            <div className="ticket-nav width--normal padding--add">
                <BackButton />
                <div className="ticket-nav__center">{props.children}</div>
                <div className="ticket-nav__btn--next">
                    <NextButton />
                </div>
            </div>
        </div>
    )
}
